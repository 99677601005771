import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { dropdownCategory, dropdownAction } from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import useFetchCourses from "../../api/FetchCourses";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const { Option } = Select;

const DropDownStepSingleSelect = ( { question, selectedOptionsFromParent, onSelect, onNext, onBack, courseName, selectedCourseName } ) => {
  const [selected, setSelected] = useState( selectedOptionsFromParent || [] );
  const [isOptionSelected, setIsOptionSelected] = useState( false );
  const [courses, setCourses] = useState();
  const [courseSelected, setCourseSelected] = useState( selectedCourseName || [] );
  const [isCourseOptions, setIsCourseOptions] = useState( false );

  const { data, isLoading, isError } = useFetchCourses();

  //useEffect( () => {
  //  const validSelectionExists = question?.options?.some( option => selected === option.id );
  //  setIsOptionSelected( validSelectionExists );
  //}, [question, selected] );

  const handleSelect = ( selectedOptions ) => {
    onSelect( selectedOptions );
    setIsOptionSelected( selectedOptions != null );
    TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
  };

  const handleCourseSelect = ( value ) => {
    setCourseSelected( value )
    courseName( value );
    setIsOptionSelected( value != null );
  };

  useEffect( () => {
    setSelected( selectedOptionsFromParent || [] );
    setCourseSelected( selectedCourseName || [] );
    if ( selectedOptionsFromParent ) {
      setIsOptionSelected( true )
    } else if ( selectedCourseName ) {
      setIsOptionSelected( true )
    } else {
      setIsOptionSelected( false );
    }
  }, [selectedOptionsFromParent, selectedCourseName] );

  useEffect( () => {
    if ( question?.text && question.text.includes( "What course are you pursuing?" ) ) {
      setCourses( data?.data.streams_and_specializations );
      setIsCourseOptions( true );
    }
  }, [question, data] );

  const { client_name } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;
  const is_question_navigation = dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );

  const handleKeyDown = ( e ) => {
    if ( e.key === 'Enter' ) {
      e.preventDefault(); // Prevent default behavior (opening the dropdown)
      onNext(); // Trigger the onNext function
    }
  };

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          {
            !isCourseOptions ?
              <>
                <Select
                  className="w-full dynamic-dropdown"
                  value={ selected }
                  onChange={ handleSelect }
                  placeholder={ question?.placeholder_text }
                  onKeyDown={ handleKeyDown }
                >
                  { question?.options?.map( ( option ) => (
                    <Option key={ option.id } value={ option.id }>
                      { option.value }
                    </Option>
                  ) ) }
                </Select>
              </>
              :
              <>
                <Select
                  showSearch={ true }
                  className="w-full dynamic-dropdown"
                  value={ courseSelected }
                  onChange={ handleCourseSelect }
                  placeholder={ question?.placeholder_text }
                >
                  { courses?.map( ( course, index ) => (
                    <Option key={ index } value={ course }>
                      { course }
                    </Option>
                  ) ) }
                </Select>
              </>
          }
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          is_question_navigation ?
            <>
              <SubmitButton onNext={ onNext } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mt-4">
              {
                question?.question_optional === "optional" && (
                  <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                    Skip
                  </button>
                )
              }
              <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                Back
              </button>
              <button
                className={ `px-4 py-2 rounded-md text-white ${ isOptionSelected ? "bg-blue-500" : "bg-blue-300" }` }
                onClick={ isOptionSelected ? onNext : null }
                style={ isOptionSelected ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
              >
                Next
              </button>
            </div>
      }
    </>
  );
};

export default DropDownStepSingleSelect;
