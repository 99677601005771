import React, { useState, useEffect } from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, useNavigate } from 'react-router-dom';
import { schoolData, dynamicData } from '../../helpers/data';
import { Image, Modal, Checkbox } from 'antd';
import { useDispatch } from 'react-redux';
import { setIsFormSubmitted, resetState, resetQuestionNumber } from '../../redux/student/studentSlice';
import { useMedia } from 'react-use';

const Instructions = () => {
    const [proceedButton, setProceedButton] = useState( false );

    const { grade, client_name } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isWide = useMedia( '(min-width: 800px)' );

    useEffect( () => {
        if ( dynamicData[client_name] ) {
            document.title = dynamicData[client_name].document_title;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
        }
    }, [client_name, dynamicData] );

    let className;
    let testId;

    if ( ( grade >= "5th" && grade <= "8th" ) ) {
        className = schoolData[client_name].primary;
    } else if ( grade === '9th' || grade === '10th' ) {
        className = schoolData[client_name].junior;
    } else if ( grade === '11th PCM' ) {
        className = schoolData[client_name].senior_pcm;
    } else if ( grade === '11th PCB' ) {
        className = schoolData[client_name].senior_pcb;
    } else {
        className = schoolData[client_name].primary
    }

    if ( ( grade === "5th" ) ) {
        testId = dynamicData[client_name].class_5_test_route;
    } else if ( grade === '6th' ) {
        testId = dynamicData[client_name].class_6_test_route;
    } else if ( grade === '7th' ) {
        testId = dynamicData[client_name].class_7_test_route;
    } else if ( grade === '8th' ) {
        testId = dynamicData[client_name].class_8_test_route;
    } else if ( grade === '9th' ) {
        testId = dynamicData[client_name].class_9_test_route
    } else if ( grade === '10th' ) {
        testId = dynamicData[client_name].class_10_test_route
    } else if ( grade === '11th PCM' ) {
        testId = dynamicData[client_name].class_11PCM_test_route
    } else if ( grade === '11th PCB' ) {
        testId = dynamicData[client_name].class_11PCB_test_route
    }

    const heading_one = className.general_instructions.heading_one || "";
    const heading_two = className.general_instructions.heading_two || "";
    const heading_three = className.general_instructions.heading_three || "";
    const heading_four = className.general_instructions.heading_four || "";
    const paragraph_one = className.general_instructions.paragraph_one || "";
    const segment_1 = className.general_instructions.segment_1 || "";
    const segment_2 = className.general_instructions.segment_2 || "";
    const heading_zero = className.general_instructions.bold_heading_one

    const heading = dynamicData[client_name].instructions_left_section.heading || "";
    const subHeading = dynamicData[client_name].instructions_left_section.sub_heading || "";
    const image = dynamicData[client_name].instructions_left_section.image_link || "";
    const copyrightText = dynamicData[client_name].instructions_left_section.copyright_text || "";
    const clientLogo = dynamicData[client_name].instructions_left_section.logo_link || "";

    const segment_1_heading_one = className.segment_one_instructions.heading_one || "";
    const segment_1_heading_two = className.segment_one_instructions.heading_two || "";
    const segment_1_heading_three = className.segment_one_instructions.heading_three || "";
    const segment_1_heading_four = className.segment_one_instructions.heading_four || "";
    const segment_1_heading_five = className.segment_one_instructions.heading_five || "";
    const segment_1_heading_six = className.segment_one_instructions.heading_six || "";

    const segment_2_heading_one = className.segment_two_instructions.heading_one || "";
    const segment_2_heading_two = className.segment_two_instructions.heading_two || "";
    const segment_2_heading_three = className.segment_two_instructions.heading_three || "";
    const segment_2_heading_four = className.segment_two_instructions.heading_four || "";
    const segment_2_heading_five = className.segment_two_instructions.heading_five || "";

    const test_intructions_heading_one = className.test_Information.heading_one || "";
    const test_intructions_heading_two = className.test_Information.heading_two || "";
    const test_intructions_heading_three = className.test_Information.heading_three || "";
    const test_intructions_heading_four = className.test_Information.heading_four || "";

    const marking_scheme_section_a_heading = className.marking_scheme?.section_a?.heading || "";
    const marking_scheme_section_a_correct_attempt = className.marking_scheme?.section_a?.correct_attempt || "";
    const marking_scheme_section_a_incorrect_attempt = className.marking_scheme?.section_a?.incorrect_attempt || "";
    const marking_scheme_section_a_unattempted = className.marking_scheme?.section_a?.unattempted || "";

    const marking_scheme_section_b_heading = className.marking_scheme?.section_b?.heading || "";
    const marking_scheme_section_b_correct_attempt = className.marking_scheme?.section_b?.correct_attempt || "";
    const marking_scheme_section_b_incorrect_attempt = className.marking_scheme?.section_b?.incorrect_attempt || "";
    const marking_scheme_section_b_unattempted = className.marking_scheme?.section_b?.unattempted || "";

    const marking_scheme_section_c_heading = className.marking_scheme?.section_c?.heading || "";
    const marking_scheme_section_c_correct_attempt = className.marking_scheme?.section_c?.correct_attempt || "";
    const marking_scheme_section_c_incorrect_attempt = className.marking_scheme?.section_c?.incorrect_attempt || "";
    const marking_scheme_section_c_unattempted = className.marking_scheme?.section_c?.unattempted || "";

    const onChange = ( event ) => {
        setProceedButton( event.target.checked );
    };

    const handleProceedToTest = () => {
        dispatch( setIsFormSubmitted( false ) );
        dispatch( resetState() );
        dispatch( resetQuestionNumber() );
        localStorage.removeItem( `textAnswers_${ testId }` );
        navigate( `/${ client_name }/test/${ grade }/${ testId }` )
    }

    return (
        <>
            {
                !isWide ?
                    <div className="flex items-center justify-center h-screen">
                        <img src='https://i.ibb.co/6spSrPQ/mobile-viewing.png' alt='optimised' class="max-w-full max-h-full" />
                    </div>
                    :
                    <>
                        <div className="container-fluid">
                            <div className="row row-height">
                                <>
                                    <div className="bg-[#3F52FD] p-0 col-xl-4 col-lg-4">
                                        <div className="flex justify-center items-center h-full min-h-full p-16 pb-9 text-white text-center relative md:h-auto md:p-[95px,30px,35px,30px] lg:p-[95px,15px,35px,15px]">
                                            <a id="logo">
                                                <img src={ clientLogo } alt="logo" width="250px" height="250px" />
                                            </a>
                                            <div className="hidden md:block">
                                                <Image src={ image } alt="" preview={ false } />
                                                <h2 className="text-white mt-2 font-bold">{ heading }</h2>
                                                <p className="text-white text-base mt-4">{ subHeading }</p>
                                            </div>
                                            <div className="hidden md:block copy">{ copyrightText }</div>
                                        </div>
                                    </div>
                                </>
                                <div className="col-xl-8 col-lg-8 h-screen min-h-screen	overflow-y-hidden" id="start">
                                    <>
                                        <div className="flex flex-col items-center h-screen space-y-8 p-5">
                                            <div className="p-8 bg-white shadow-lg rounded-lg w-3/4 mx-auto overflow-y-auto">
                                                <div className='text-center mb-5'>
                                                    <h1 className="text-2xl font-bold mb-4">Test Instructions</h1>
                                                </div>
                                                <p className="mb-6 text-base font-medium">{ test_intructions_heading_one } { grade }</p>
                                                <p className="mb-6 text-base font-medium">{ test_intructions_heading_two }</p>
                                                <p className="mb-6 text-base font-medium">{ test_intructions_heading_three }</p>
                                                <p className="mb-6 text-base font-medium">{ test_intructions_heading_four }</p>
                                                <div className='text-center mb-5'>
                                                    <h1 className="text-2xl font-bold mb-4">General Instructions</h1>
                                                </div>
                                                <p className="mb-6 text-base font-medium">{ heading_zero }</p>
                                                <p className="mb-6 text-base font-medium">{ heading_one }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_1 }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_2 }</p>
                                                <p className="mb-6 text-base font-medium">{ heading_two }</p>
                                                <p className="mb-6 text-base font-medium">{ heading_three }</p>
                                                <p className="mb-6 text-base font-medium">{ paragraph_one }</p>
                                                <p className="mb-6 text-base font-medium">{ heading_four }</p>
                                                <div className='text-center mb-5'>
                                                    <h1 className="text-2xl font-bold mb-4">Segment One Instructions</h1>
                                                </div>
                                                <p className="mb-6 text-base font-medium">{ segment_1_heading_one }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_1_heading_two }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_1_heading_three }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_1_heading_four }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_1_heading_five }</p>
                                                <p className="mb-8 text-base font-medium">{ segment_1_heading_six }</p>
                                                <div className='text-center mb-5'>
                                                    <h1 className="text-2xl font-bold mb-4">Segment Two Instructions</h1>
                                                </div>
                                                <p className="mb-6 text-base font-medium">{ segment_2_heading_one }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_2_heading_two }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_2_heading_three }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_2_heading_four }</p>
                                                <p className="mb-6 text-base font-medium">{ segment_2_heading_five }</p>
                                                <div className='text-center mb-5'>
                                                    <h1 className="text-2xl font-bold mb-4">Marking Scheme</h1>
                                                </div>
                                                <table className="table-auto mx-auto">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                marking_scheme_section_a_heading &&
                                                                <th className="border px-4 py-2">Section</th>
                                                            }
                                                            <th className="border px-4 py-2">Correct Attempt</th>
                                                            <th className="border px-4 py-2">Incorrect</th>
                                                            <th className="border px-4 py-2">Unattempted</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            className.marking_scheme?.section_a &&
                                                            <tr>
                                                                {
                                                                    marking_scheme_section_a_heading &&
                                                                    <td className="border px-4 py-2">{ marking_scheme_section_a_heading }</td>
                                                                }
                                                                <td className="border px-4 py-2">{ marking_scheme_section_a_correct_attempt }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_a_incorrect_attempt }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_a_unattempted }</td>
                                                            </tr>
                                                        }
                                                        {
                                                            className.marking_scheme?.section_b &&
                                                            <tr>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_b_heading }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_b_correct_attempt }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_b_incorrect_attempt }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_b_unattempted }</td>
                                                            </tr>
                                                        }
                                                        {
                                                            className.marking_scheme?.section_c &&
                                                            <tr>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_c_heading }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_c_correct_attempt }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_c_incorrect_attempt }</td>
                                                                <td className="border px-4 py-2">{ marking_scheme_section_c_unattempted }</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className='flex flex-col text-center items-center justify-center mt-5'>
                                                    <Checkbox onChange={ onChange } className='mb-5'>
                                                        I have read all the instructions.
                                                    </Checkbox>
                                                    <button
                                                        className={ `rounded-md text-white p-3 mb-2 ${ proceedButton ? 'bg-blue-500' : 'bg-blue-300'
                                                            }` }
                                                        style={ proceedButton ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                                                        disabled={ !proceedButton }
                                                        onClick={ handleProceedToTest }
                                                    >
                                                        Proceed to Test
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default Instructions