import React, { useState, useEffect } from 'react';
import { notification, Spin } from 'antd';
import { useMedia } from 'react-use';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams, useNavigate } from 'react-router-dom';
import { setIsFormSubmitted, setIsLoadingScreen } from '../../redux/student/studentSlice';
import { useDispatch } from 'react-redux';
import { dynamicData } from '../../helpers/data';
import useRequestOTPEmail from '../../api/RequestOtpEmail';
import useVerifyOTPEmail from '../../api/VerifyOtpEmail';
import useFetchStudentDataEmail from '../../api/FetchStudentDetailsEmail';

const SignInEmail = () => {

    const [email, setEmail] = useState( '' );
    const isWide = useMedia( '(min-width: 800px)' );
    const { client_name } = useParams();


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { requestOTP } = useRequestOTPEmail();
    const { data, isLoading, error, verifyOTP } = useVerifyOTPEmail();
    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [showOtpBoxes, setShowOtpBoxes] = useState( false );

    const handleSignInEmail = () => {
        if ( email ) {
            FetchStudentDataEmail( email, client_name )
        }
    };

    //requestOTP( email, client_name );
    //setShowOtpBoxes( true );

    useEffect( () => {
        if ( studentData && !studentDataLoading ) {
            requestOTP( email, client_name )
            setShowOtpBoxes( true );
            const grade = studentData.data.grade;
            const student_id = studentData.data.id;
            const student_name = studentData.data.name;
            const userAssessmentResponseID = studentData.data.assessment_id;
            const assessment_status = studentData.data.assessment_status;
            const updatedAssessmentStatus = assessment_status === null ? 1 : assessment_status;
            if ( userAssessmentResponseID !== null ) {
                localStorage.setItem( 'userAssessmentResponseID', userAssessmentResponseID )
            }
            localStorage.setItem( 'student_roll_number', student_id );
            localStorage.setItem( 'student_name', student_name );
            localStorage.setItem( 'is_completed', updatedAssessmentStatus )

            if ( grade && !grade.includes( 'th' ) ) {
                const updatedGrade = grade + 'th';
                localStorage.setItem( 'student_grade', updatedGrade );
            } else {
                localStorage.setItem( 'student_grade', grade )
            }
        } else if ( studentDataError ) {
            notification.error( {
                message: 'Incorrect Email id',
                description: 'Please check your Email.'
            } );
        }
    }, [studentData, studentDataLoading] );

    const handleVerifyOtp = async () => {
        const otp = otpValues.join( "" );
        // Make an API call to verify OTP
        verifyOTP( email, otp );
        setShowOtpBoxes( false );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                notification.success( {
                    message: 'OTP Verified',
                    description: 'Your OTP has been successfully verified.'
                } );

                const student_grade = localStorage.getItem( 'student_grade' );
                const assessment_status = localStorage.getItem( 'is_completed' );
                console.log( 'assessment_status', assessment_status );

                if ( assessment_status === null || assessment_status !== '3' ) {
                    dispatch( setIsFormSubmitted( false ) );
                    navigate( `/${ client_name }/test/${ student_grade }/instructions` );
                } else {
                    dispatch( setIsFormSubmitted( true ) );
                    dispatch( setIsLoadingScreen( true ) );
                    notification.success( {
                        message: 'You\'ve already given the test.',
                    } );
                    navigate( `/${ client_name }/test/${ student_grade }/thank_you` );
                }
            } else if ( error ) {
                notification.error( {
                    message: 'OTP Verification Failed',
                    description: 'The OTP you entered is incorrect. Please try again.'
                } );
            }
        }
    }, [data, isLoading, error] );

    useEffect( () => {
        if ( dynamicData[client_name] ) {
            document.title = dynamicData[client_name].document_title;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
        }
    }, [client_name, dynamicData] );

    return (
        <div>
            <div className="flex flex-col md:flex-row min-h-screen">
                <div className="bg-gradient-to-r from-yellow-300 to-orange-400 flex flex-col justify-center items-center md:w-2/5">
                    {
                        isWide ?
                            <>
                                <img className="mb-5" src="https://i.ibb.co/Vv2zyhR/jclogo.png" alt="logo" width="200" height="200" />
                            </>
                            :
                            <></>
                    }
                    <img className={ `${ isWide ? "" : "" }` } src={ `${ isWide ? 'https://i.ibb.co/B3nMWzk/Frame-1000006445.png' : 'https://i.ibb.co/ZMk1bBh/icat-logo.png' }` } alt="auth-graphic" border="0" />
                </div>
                <div className="flex justify-center items-center w-full md:w-3/5 mt-3 mb-3">
                    <div className="w-3/4 justify-center items-center px-4">
                        <h1 className="text-2xl font-bold mb-1">Enter your registered email we will send a code to log you in</h1>
                        <h1 className='text-xl font-semibold mt-2'>[Don't forget to check your Spam]</h1>
                        <div className="flex flex-col mt-5">
                            <div className="mb-4 w-1/2">
                                <input
                                    type="text"
                                    id="email"
                                    className="mt-1 p-3 block w-full border-2 border-black-300 rounded-md focus:ring-2 focus:border-blue-500"
                                    required
                                    value={ email }
                                    onChange={ ( e ) => setEmail( e.target.value ) }
                                    placeholder="Ex - abc@gmail.com"
                                />
                            </div>
                            { showOtpBoxes ? (
                                <div className="flex flex-col w-1/2">
                                    <div className="flex flex-row w-3/4">
                                        {
                                            otpValues.map( ( value, index ) => (
                                                <input
                                                    key={ index }
                                                    id={ `otp-input-${ index }` }
                                                    className="w-3/4 mr-2 border-2 border-gray-300 rounded-md p-2 text-center focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition-all duration-300"
                                                    value={ value }
                                                    maxLength={ 1 }
                                                    onChange={ ( e ) => handleOTPChange( e, index ) }
                                                />
                                            ) )
                                        }
                                    </div>
                                    <button
                                        className="w-full md:w-1/2 flex mt-3 justify-center py-3 px-5 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        onClick={ handleVerifyOtp }
                                    >
                                        Verify OTP
                                    </button>
                                </div>
                            ) : (
                                <div className="flex w-1/2">
                                    <button
                                        type="submit"
                                        className="w-full md:w-1/2 flex justify-center py-3 px-5 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        onClick={ handleSignInEmail }
                                    >
                                        Send OTP
                                    </button>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInEmail;




