import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, CREATE_STUDENT
} from '../utils/constants/constants';


const useCreateStudentCode = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [isCalled, setIsCalled] = useState( false );
    const API_URL = `${ BASE_URL }${ CREATE_STUDENT }`;

    const createStudentCode = async ( studentName, studentGrade, studentEmail, student_institute_code, clientName , responseId, studentDateOfBirth) => {
        if ( isCalled ) {
            console.warn( "createStudentCode has already been called." );
            return;
        }

        setIsLoading( true );
        setIsCalled( true );
        const requestBody = {
            name: studentName,
            grade: studentGrade,
            email: studentEmail,
            institute_code: student_institute_code,
            client: clientName,
            response_id : responseId,
            dob : studentDateOfBirth
        };

        try {
            const res = await axios.post( API_URL, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, createStudentCode };
};

export default useCreateStudentCode;
