import { Button, Radio, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { singleChoiceCategory, singleChoiceAction } from "../../utils/GoogleAnalytics/events/categories";
import AdvertiseMentModal from "../../helpers/AdvertisementModal";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useMedia } from 'react-use';

const useImageDimensions = ( src, maxWidth, maxHeight ) => {
  const [dimensions, setDimensions] = useState( { width: 0, height: 0 } );

  useEffect( () => {
    const image = new Image();

    image.onload = () => {
      const aspectRatio = image.width / image.height;

      let newHeight = maxHeight;
      let newWidth = maxHeight * aspectRatio;

      if ( newWidth > maxWidth ) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      }

      setDimensions( { width: newWidth, height: newHeight } );
    };

    image.src = src;
  }, [src, maxWidth, maxHeight] );

  return dimensions;
};

const RenderOptionText = ( { option } ) => {
  const maxWidth = window.innerWidth - 150; // Use screen width

  const isWide = useMedia( '(min-width: 450px)' );
  const maxHeight = isWide ? 200 : 50; // Define max height

  // Move the image source extraction logic outside of the conditional block
  const imgSrcMatch = option.value.match( /src=["'](.*?)["']/ );
  const imgSrc = imgSrcMatch ? imgSrcMatch[1] : null;

  // Always call the hook
  const dimensions = useImageDimensions( imgSrc, maxWidth, maxHeight );

  if ( imgSrc ) {
    // Adjust width and height as needed
    return (
      <span
        dangerouslySetInnerHTML={ {
          __html: option.value.replace(
            /<img/g,
            `<img style="max-width: ${ dimensions.width }px; height: ${ dimensions.height }px;"`
          ),
        } }
      />
    );
  } else {
    return <span>{ option.value }</span>;
  }
};

const CustomRadio = ( { option, selected, onSelect, onNext } ) => {

  const handleKeyDown = ( e ) => {
    if ( e.key === "Enter" ) {
      onNext();
    }
  };

  return (
    <Radio
      className="flex items-center cursor-pointer bg-white p-2 rounded-md"
      value={ option.id }
      checked={ selected?.id === option.id }
      onChange={ () => onSelect( option ) }
      onKeyDown={ handleKeyDown }
      tabIndex={ 0 }
    >
      <RenderOptionText option={ option } />
    </Radio>
  );
};

const QuestionStep = ( { question, selected, onSelect, onNext, onBack, onClearAll } ) => {
  const [isOptionSelected, setIsOptionSelected] = useState( false );

  const handleSelect = ( option ) => {
    onSelect( option );
    setIsOptionSelected( true );
    TrackGoogleAnalyticsEvent( singleChoiceCategory, singleChoiceAction );

    if ( question.text.includes( "Hey Fellow Learner! What grade are you in?" ) ) {
      localStorage.setItem( 'studentGrade', option.value );
    } else if ( question.text.includes( "Would you prefer the test to be online or offline?" ) ) {
      localStorage.setItem( 'examination_mode', option.value )
    } else if ( question.text.includes( "What grade are you in right now?" ) ) {
      localStorage.setItem( 'studentGrade', option.value );
    }
  };

  const { client_name } = useParams();


  const isPagination = dynamicData[client_name].is_pagination;
  const is_question_navigation = dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );

  useEffect( () => {
    if ( selected?.id ) {
      setIsOptionSelected( true );
    } else {
      setIsOptionSelected( false );
    }
  }, [question, selected] );

  const handleClearAll = ( option ) => {
    onClearAll( option )
  }

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <div className="space-y-2">
            { question?.options?.map( ( option ) => (
              <CustomRadio
                key={ option.id }
                option={ option }
                selected={ selected }
                onSelect={ handleSelect }
                onNext={ onNext }
              />
            ) ) }
          </div>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          is_question_navigation ?
            <>
              <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mt-4">
              {
                question?.question_optional === "optional" && (
                  <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                    Skip
                  </button>
                )
              }
              <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack } >
                Back
              </button>
              <button
                className={ `px-4 py-2 rounded-md text-white ${ isOptionSelected ? "bg-blue-500" : "bg-blue-300" }` }
                onClick={ isOptionSelected ? onNext : null }
                style={ isOptionSelected ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
              >
                Next
              </button>
            </div>
      }
    </>
  );
};

export default QuestionStep;
