import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import useFetchSchoolData from "../../api/FetchSchoolData";
import { notification } from "antd";

const NumberInputStep = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
    const [isAnswerNumeric, setIsAnswerNumeric] = useState( false );

    const { fetchData, response, error, isLoading } = useFetchSchoolData();

    const { client_name } = useParams();
    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const institute_code = query.get( 'institute_id' ) || localStorage.getItem( 'schoolId' );

    const [schoolId, setSchoolId] = useState( institute_code || null );

    useEffect( () => {
        if ( answer ) {
            setIsAnswerNumeric( true );
        } else if ( institute_code && question.text.includes( "Enter your school ID" ) ) {
            setIsAnswerNumeric( true );
        } else {
            setIsAnswerNumeric( false );
        }
    }, [question, answer] );

    const handleInputChange = ( e, validationType ) => {
        const inputValue = e.target.value;

        // Use a regular expression to filter out non-numeric characters
        const numericValue = inputValue.replace( /[^0-9]/g, "" );

        // Add "SCH-" before the numeric value
        const formattedValue = `SCH-${ numericValue }`;

        setIsAnswerNumeric( !!numericValue ); // Check if there are numeric digits
        setSchoolId( numericValue );
        onAnswerChange( numericValue );
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );
    };


    const isPagination = dynamicData[client_name].is_pagination;
    const is_question_navigation = dynamicData[client_name].is_question_navigation &&
        window.location.href.includes( 'test' );

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    const handleSubmit = async () => {
        if ( question.text.includes( "Enter your school ID" ) ) {
            try {
                const responseData = await fetchData( schoolId );
                // handle success using responseData
                notification.success( {
                    message: 'School Id Verified',
                } );
                localStorage.setItem( 'schoolId', schoolId );
                localStorage.setItem( 'schoolName', responseData?.name );
                onNext();
            } catch ( error ) {
                // handle error
                notification.error( {
                    message: 'Incorrect School Id',
                    description: 'The school id entered is incorrect. Please try again.',
                } );
            }
        } else {
            onNext();
        }
    };

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <QuestionAndDescription question={ question } />
                    <div>
                        <input
                            type="text"
                            className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                            value={
                                question.text.includes( "Enter your school ID" )
                                    ? institute_code
                                    : answer
                            }
                            onChange={ ( e ) => handleInputChange( e, question.validation ) }
                            placeholder={ question?.placeholder_text }
                            disabled={ institute_code && question.text.includes( "Enter your school ID" ) }
                            onKeyDown={ handleKeyDown }
                        />
                    </div>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    is_question_navigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mt-4">
                            { question?.question_optional === "optional" && (
                                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext }>
                                    Skip
                                </button>
                            ) }
                            <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                                Back
                            </button>
                            <button
                                className={ `px-4 py-2 rounded-md text-white ${ isAnswerNumeric ? "bg-blue-500" : "bg-blue-300" }` }
                                onClick={ isAnswerNumeric ? handleSubmit : null }
                                style={ isAnswerNumeric ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                            >
                                Next
                            </button>
                        </div>
            }
        </>
    );
};

export default NumberInputStep;
