import React, { useState } from "react";
import { Button, Checkbox } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { multipleChoiceCategory, multipleChoiceAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const CustomCheckbox = ( { option, selected, onSelect, onNext } ) => {

  const handleKeyDown = ( e ) => {
    if ( e.key === "Enter" ) {
      onNext();
    }
  };

  return (
    <label
      className="flex items-center cursor-pointer bg-white p-2 rounded-md"
      htmlFor={ `checkbox-${ option.id }` }
    >
      <Checkbox
        id={ `checkbox-${ option.id }` }
        checked={ selected?.includes( option.id ) }
        onChange={ () => onSelect( option ) }
        onKeyDown={ handleKeyDown }
        tabIndex={ 0 }
      />
      <span className="ml-2">{ option.value }</span>
    </label>
  );
};

const MultiChoiceStep = ( {
  question,
  onSelect,
  onNext,
  onBack,
  selectedOptionsFromParent,
} ) => {
  const [selectedOptions, setSelectedOptions] = useState(
    selectedOptionsFromParent || []
  );

  const handleSelect = ( option ) => {
    let updatedOptions = [...selectedOptions];

    if ( updatedOptions.includes( option.id ) ) {
      updatedOptions = updatedOptions.filter( ( id ) => id !== option.id );
    } else {
      updatedOptions.push( option.id );
    }

    setSelectedOptions( updatedOptions );
    onSelect( updatedOptions );

    TrackGoogleAnalyticsEvent( multipleChoiceCategory, multipleChoiceAction );
  };


  const { client_name } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;
  const is_question_navigation = dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );

  const handleClearAll = () => {
    setSelectedOptions( [] ); // Clear the selected options
    onSelect( [] ); // Notify the parent component about the cleared options
  };


  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <div className="justify-content space-between w-full">
            { question?.options?.map( ( option ) => (
              <CustomCheckbox
                key={ option.id }
                option={ option }
                selected={ selectedOptions }
                onSelect={ handleSelect }
                onNext={ onNext }
              />
            ) ) }
          </div>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          is_question_navigation ?
            <>
              <SubmitButton onNext={ onNext } onClearAll={ handleClearAll } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mt-4">
              {
                question?.question_optional === "optional" && (
                  <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                    Skip
                  </button>
                )
              }
              <button
                className="px-4 py-2 rounded-md text-black border-2 bg-white "
                onClick={ onBack }
              >
                Back
              </button>
              <button
                className={ `px-4 py-2 rounded-md text-white ${ selectedOptions.length ? "bg-blue-500" : "bg-blue-300"
                  }` }
                onClick={ selectedOptions.length ? onNext : null }
                style={
                  selectedOptions.length
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed" }
                }
              >
                Next
              </button>
            </div>
      }
    </>
  );
};

export default MultiChoiceStep;
