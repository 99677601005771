import React from 'react';
import { useMedia } from 'react-use';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import SignInEmail from './SigninEmail';
import SignInRollNumber from './SigninRollNumber';
import SignInCouponCode from './SigninCouponCode';

const SignIn = () => {
    const isWide = useMedia( '(min-width: 800px)' );
    const { client_name } = useParams();
    const SignInWithEmail = dynamicData[client_name].verify_with_email;
    const SignInWithCouponCode = dynamicData[client_name].coupon_code_verification

    return (
        <div>
            {
                !isWide ?
                    <div className="flex items-center justify-center h-screen">
                        <img src='https://i.ibb.co/6spSrPQ/mobile-viewing.png' alt='optimised' class="max-w-full max-h-full" />
                    </div>
                    :
                    <>
                        {
                            SignInWithEmail ?
                                <>
                                    <SignInEmail />
                                </> :
                                SignInWithCouponCode ?
                                    <>
                                        <SignInCouponCode />
                                    </> :
                                    <>
                                        <SignInRollNumber />
                                    </>
                        }
                    </>
            }
        </div>
    );
};

export default SignIn;




