import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { dropdownCategory, dropdownAction } from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const { Option } = Select;

const DropDownStepMultipleSelect = ( { question, selectedOptionsFromParent, onSelect, onNext, onBack } ) => {
  const [selected, setSelected] = useState( selectedOptionsFromParent || [] );
  const [isOptionSelected, setIsOptionSelected] = useState( false );

  useEffect( () => {
    const validSelectionExists = question?.options?.some( ( option ) => selected?.includes( option.id ) );
    setIsOptionSelected( validSelectionExists );
  }, [question, selected] );

  // Check if question?.description includes the word "3"
  const isDescriptionIncludes3 = question?.description?.includes( "3" );

  const handleSelect = ( selectedOptions ) => {
    // If description includes "3" and the number of selected options is greater than 3, restrict the selection
    if ( isDescriptionIncludes3 && selectedOptions.length > 3 ) {
      // You can show a message or take appropriate action here
      return;
    }

    onSelect( selectedOptions );
    setIsOptionSelected( selectedOptions );
    TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
  };

  useEffect( () => {
    setSelected( selectedOptionsFromParent || [] );
  }, [selectedOptionsFromParent] );


  const { client_name } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;
  const is_question_navigation = dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );

  const handleKeyDown = ( e ) => {
    if ( e.key === "Enter" ) {
      onNext();
    }
  };

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <Select
            className="w-full dynamic-dropdown"
            value={ Array.isArray( selected ) ? selected : [] }
            onChange={ handleSelect }
            showSearch={ true }
            mode="multiple"
            placeholder={ question?.placeholder_text }
            filterOption={ ( input, option ) =>
              option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
            }
            onKeyDown={ handleKeyDown }
            tabIndex={ 0 } 
          >
            { question?.options?.map( ( option ) => (
              <Option key={ option.id } value={ option.id }>
                { option.value }
              </Option>
            ) ) }
          </Select>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          is_question_navigation ?
            <>
              <SubmitButton onNext={ onNext } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mt-4">
              { question?.question_optional === "optional" && (
                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext }>
                  Skip
                </button>
              ) }
              <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                Back
              </button>
              <button
                className={ `px-4 py-2 rounded-md text-white ${ isOptionSelected ? "bg-blue-500" : "bg-blue-300"
                  }` }
                onClick={ isOptionSelected ? onNext : null }
                style={ isOptionSelected ? { cursor: "pointer" } : { cursor: "not-allowed" } }
              >
                Next
              </button>
            </div>
      }
    </>
  );
};

export default DropDownStepMultipleSelect;
