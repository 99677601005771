import React, { useState, useEffect } from 'react';
import useAuthenticateStudent from '../../api/AuthenticateStudent';
import { DatePicker, notification, Spin } from 'antd';
import { useMedia } from 'react-use';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams, useNavigate } from 'react-router-dom';
import { setIsFormSubmitted, setIsLoadingScreen } from '../../redux/student/studentSlice';
import { useDispatch } from 'react-redux';
import { dynamicData } from '../../helpers/data';

const SignInRollNumber = () => {

    const [rollNumber, setRollNumber] = useState( '' );
    const [dob, setDOB] = useState( '' );
    const [schoolCode, setSchoolCode] = useState( '' );
    const isWide = useMedia( '(min-width: 800px)' );
    const { client_name } = useParams();

    const handleAnswerChange = ( date ) => {
        setDOB( date );
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { AuthenticateStudent, response: AuthenticateStudentResponse, error: AuthenticateStudentError, isLoading: AuthenticateStudentLoading } = useAuthenticateStudent();

    const handleSignIn = async ( e ) => {
        e.preventDefault();
        const formattedDate = new Date( dob ).toISOString().split( 'T' )[0] || null;
        await AuthenticateStudent( schoolCode, formattedDate, rollNumber );
    };

    useEffect( () => {
        if ( dynamicData[client_name] ) {
            document.title = dynamicData[client_name].document_title;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
        }
    }, [client_name, dynamicData] );

    useEffect( () => {
        if ( AuthenticateStudentResponse ) {
            console.log( AuthenticateStudentResponse );
            const grade = AuthenticateStudentResponse.grade;
            const student_name = AuthenticateStudentResponse.name;
            const student_roll_number = AuthenticateStudentResponse.student_code;
            const assessment_status = AuthenticateStudentResponse.assessment_status;
            console.log( assessment_status )
            const userAssessmentResponseID = AuthenticateStudentResponse.assessment_id
            localStorage.setItem( 'student_name', student_name );
            localStorage.setItem( 'student_roll_number', student_roll_number );
            localStorage.setItem( 'is_completed', 1 );

            if ( assessment_status === null || ( assessment_status !== null && assessment_status !== 3 ) ) {
                Object.keys( localStorage ).forEach( ( key ) => {
                    if ( key !== 'student_name' && key !== 'student_roll_number' && key !== 'is_completed' ) {
                        localStorage.removeItem( key );
                    }
                } );
                dispatch( setIsFormSubmitted( false ) );
                notification.success( {
                    message: 'Sign In Successful',
                } );
                navigate( `/${ client_name }/test/${ grade }/instructions` );
            } else {
                localStorage.setItem( 'userAssessmentResponseID', userAssessmentResponseID )
                dispatch( setIsFormSubmitted( true ) );
                localStorage.setItem( 'isFormSubmitted', true )
                dispatch( setIsLoadingScreen( true ) );
                localStorage.setItem( 'isLoadingScreen', true )
                notification.success( {
                    message: 'You\'ve already given the test.',
                } );
                navigate( `/${ client_name }/test/${ grade }/thank_you` );
            }
        } else if ( AuthenticateStudentError ) {
            notification.error( {
                message: 'Sign In Failed',
                description: 'Invalid credentials. Please check your roll number, date of birth, and school code.',
            } );
        }
    }, [AuthenticateStudentResponse, AuthenticateStudentError] );

    return (
        <div>
            <div className="flex flex-col md:flex-row min-h-screen">
                <div className="bg-gradient-to-r from-yellow-300 to-orange-400 flex flex-col justify-center items-center md:w-2/5">
                    {
                        isWide ?
                            <>
                                <img className="mb-5" src="https://i.ibb.co/Vv2zyhR/jclogo.png" alt="logo" width="200" height="200" />
                            </>
                            :
                            <></>
                    }
                    <img className={ `${ isWide ? "" : "" }` } src={ `${ isWide ? 'https://i.ibb.co/B3nMWzk/Frame-1000006445.png' : 'https://i.ibb.co/ZMk1bBh/icat-logo.png' }` } alt="auth-graphic" border="0" />
                </div>
                { AuthenticateStudentLoading ?
                    <div className="flex items-center justify-center w-1/2">
                        <Spin tip="Loading" size="large" />
                    </div>
                    :
                    <>
                        <div className="flex justify-center items-center w-full md:w-3/5 mt-3 mb-3">
                            <div className="w-full max-w-md justify-center items-center px-4">
                                <h1 className="text-4xl font-bold mb-2">Sign In</h1>
                                <p className="mb-10 text-base text-gray-400 font-light">Fill in the Following Details to Login to the Test.</p>
                                <form className="space-y-8">
                                    <div className="mb-4">
                                        <label htmlFor="rollNumber" className="block text-base font-medium text-gray-400">
                                            Roll number
                                        </label>
                                        <p className="mb-0 text-base text-gray-400 font-light">[Generated for You upon Registrations]</p>
                                        <input
                                            type="text"
                                            id="rollNumber"
                                            className="mt-1 p-3 block w-full border-2 border-black-300 rounded-md focus:ring-2 focus:border-blue-500"
                                            required
                                            value={ rollNumber }
                                            onChange={ ( e ) => setRollNumber( e.target.value ) }
                                            placeholder='Ex - 67493'
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="dob" className="block text-base font-medium text-gray-400">
                                            Date of birth
                                        </label>
                                        <DatePicker
                                            className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                                            onChange={ handleAnswerChange }
                                            value={ dob }
                                            popupStyle={ {
                                                width: '290px', height: '200px',
                                            } }
                                            showToday={ false }
                                        />
                                    </div>
                                    <div className="mb-10">
                                        <label htmlFor="schoolCode" className="block text-base font-medium text-gray-400">
                                            School code
                                        </label>
                                        <p className="mb-0 text-base text-gray-400 font-light">[Provided to You by Your School] </p>
                                        <input
                                            type="text"
                                            id="schoolCode"
                                            className="mt-1 block p-3 w-full border-2 border-black-300 rounded-md focus:ring-2 focus:border-blue-500"
                                            required
                                            value={ schoolCode }
                                            onChange={ ( e ) => setSchoolCode( e.target.value ) }
                                            placeholder='Ex - 254'
                                        />
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            type="submit"
                                            className="w-full md:w-1/2 flex justify-center py-3 px-5 border border-transparent rounded-md shadow-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            onClick={ handleSignIn }
                                        >
                                            Sign in
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default SignInRollNumber;




