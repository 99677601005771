import React, { useState, useEffect } from 'react';
import EnableTick from '../../assets/enable_tick.svg';
import DisableTick from '../../assets/disable_tick.svg';
import LoaderAnimation from '../../assets/Loader Animation.gif';
import { useSelector } from 'react-redux';
import { dynamicData } from "../../helpers/data";
import { useParams } from 'react-router-dom';

const LoadingAnimation = ( { onNext, onBack } ) => {

    const handleOk = () => {
        onNext();
    };

    const handleBack = () => {
        onBack();
    }

    const { client_name } = useParams();

    const isClient = useSelector( state => state.client.isClient );
    const isStudent = useSelector( state => state.student.isStudent )
    const isParent = useSelector( state => state.parent.isParent )

    //const heading = isClient && isStudent ? dynamicData.nsit.student_content.loading_page.heading : isClient && isParent ? dynamicData.nsit.parent_content.loading_page.heading : dynamicData.default.loading_page.heading;
    //const pointers = isClient && isStudent ? dynamicData.nsit.student_content.loading_page.pointers : isClient && isParent ? dynamicData.nsit.parent_content.loading_page.pointers : dynamicData.default.loading_page.pointers;

    let heading = dynamicData.ttm.loading_page.heading;
    let pointers = dynamicData.ttm.loading_page.pointers;

    if ( isClient ) {
        if ( isStudent ) {
            heading = dynamicData[client_name]?.student_content?.loading_page?.heading || heading;
            pointers = dynamicData[client_name]?.student_content?.loading_page?.pointers || pointers;
        } else if ( isParent ) {
            heading = dynamicData[client_name]?.parent_content?.loading_page?.heading || heading;
            pointers = dynamicData[client_name]?.parent_content?.loading_page?.pointers || pointers;
        }
    } else {
        heading = dynamicData[client_name]?.loading_page?.heading || heading;
        pointers = dynamicData[client_name]?.loading_page?.pointers || pointers;
    }

    const [showReportButton, setShowReportButton] = useState( false );

    const initialEnableIcons = Array( pointers.length ).fill( false );

    const [enableIcons, setEnableIcons] = useState( initialEnableIcons );


    useEffect( () => {
        // Create timers based on the length of pointers
        const timers = pointers.map( ( _, index ) => {
            return setTimeout( () => {
                setEnableIcons( prevState => {
                    const newState = [...prevState];
                    newState[index] = true;
                    return newState;
                } );
            }, ( index + 1 ) * 1000 ); // Increase the time for each pointer
        } );

        // Show report button after all pointers are processed
        const reportButtonTimer = setTimeout( () => {
            setShowReportButton( true );
        }, ( pointers.length + 1 ) * 1000 );

        timers.push( reportButtonTimer );

        return () => {
            timers.forEach( ( timer ) => clearTimeout( timer ) );
        };
    }, [pointers] );

    const pointersList = pointers.map( ( pointer, index ) => (
        <div key={ index } className="flex item-start">
            <img src={ enableIcons[index] ? EnableTick : DisableTick } className="w-6 h-6" alt="Tick Icon" />
            <p className={ `pl-2 text-base font-normal ${ enableIcons[index] ? 'text-black' : 'text-[#706E84]' } sm:text-sm md:text-sm` }>{ pointer }</p>
        </div>
    ) );

    return (
        <>
            <div className="font-poppins w-full flex flex-col items-center justify-center">
                <div className="text-center">
                    <img src={ LoaderAnimation } className="mx-auto w-[22.5rem]" alt="SheeHulk" />
                </div>
                <div className="text-center">
                    <h1 className="mb-4 text-lg font-semibold text-[#352958] sm:text-xl md:text-2xl">{ heading }</h1>
                    <div className='text-left'>
                        { pointersList }
                    </div>
                </div>
                {
                    showReportButton &&
                    <>
                        <div className='flex flex-row gap-5'>
                            <button
                                className="px-4 py-2 rounded-md border-2 text-white bg-blue-500 transition-transform transform hover:translate-y-[-5px]"
                                onClick={ handleBack }
                            >
                                Modify Answers
                            </button>
                            <button
                                className="px-4 py-2 rounded-md border-2 text-white bg-blue-500 transition-transform transform hover:translate-y-[-5px]"
                                onClick={ handleOk }
                            >
                                Go to Reports
                            </button>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default LoadingAnimation;
