import { useState } from "react";
import axios from "axios";
import { notification } from 'antd';
import { BASE_URL, FETCH_DETAILS_FROM_EMAIL } from "../utils/constants/constants";

const useFetchStudentDataEmail = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FETCH_DATA = `${ BASE_URL }${ FETCH_DETAILS_FROM_EMAIL }`

    console.log( FETCH_DATA )
    
    const FetchStudentDataEmail = async ( email, client_name ) => {

        setIsLoading( true );

        try {
            const res = await axios.get( `${ FETCH_DATA }/?email=${ email }&client=${ client_name }` );
            if ( !res.data.status_code ) {
                setResponse( res );
                setError( null );
            } else {
                notification.error( {
                    message: 'Incorrect Email id',
                    description: 'Please check your Email.'
                } );
            }
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchStudentDataEmail };
};

export default useFetchStudentDataEmail;