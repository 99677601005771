import React, { useState, useEffect } from "react";
import { Image, Spin, notification, Modal } from 'antd';
import { questionsConfig } from "./questionsConfig";
import Review from "./ReviewPage/Review";
import QuestionStep from "./SingleChoiceQuestions/SingleChoiceStep";
import MultiChoiceStep from "./MultipleChoiceQuestions/MultiChoiceStep";
import DropDownStepMultipleSelect from "./DropDownQuestions/DropDownStepMultipleSelect";
import DropDownStepSingleSelect from "./DropDownQuestions/DropDownStepSingleSelect";
import TextInputStep from "./InputTypeText/TextInputStep";
import QuestionStepValidation from "./ValidationTypeQuestions/QuestionStepValidation";
import NumberInputStep from "./InputTypeNumber/NumberInputStep";
import PhoneNumberInputStep from "./InputTypeNumber/PhoneNumberInputStep";
import MultiChoiceStepLink from "./UploadFileType/UploadFileTypeStep";
import EmailTypeStep from "./EmailTypeQuestion/EmailTypeStep";
import StateSelectionQuestion from "./StateSelectionQuestion/StateSelectionQuestion";
import "../css/bootstrap.min.css";
import "../css/bootstrap.css";
import InfoGraphic from "../img/InfoGraphic.png"
import Logo from "../img/logo.png"
import "../css/style.css";
import ProgressBar from "@ramonak/react-progress-bar";
import useFetchQuestion from "../api/FetchQuestions";
import useCreateUserAssessmentResponse from "../api/CreateUserAccessment";
import useCreateUserQuestionResponse from "../api/CreateUserQuestionResponse";
import { useParams, useNavigate } from 'react-router-dom';
import VerificationTypeQuestion from "./Verification/EmailVerification";
import useRequestOTP from "../api/useRequestOtp";
import MobileVerification from "./Verification/PhoneNumberVerification";
import EmailVerification from "./Verification/EmailVerification";
import LeftSideBar from "./LeftSideBar";
import AdvertiseMentModal from "../helpers/AdvertisementModal";
import { setIsStudent, setIsParent, setIsSchoolRegistration, setIsStudentRegistration, setIsConsentForm, setIsLoadingScreen } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicData, imageQuestion } from "../helpers/data";
import NameInputStep from "./InputTypeText/NameTypeQuestion";
import DobInputType from "./DobInputQuestion/DobInputQuestion";
import ThankYouPage from "./ThankYouPage/ThankYou";
import FlexibleInputStep from "./FlexibleInputStep/FlexibleInputStep";
import IndianStateSelectionQuestion from "./StateSelectionQuestion/IndianStateSelectionQuestion";
import TestTimer from "./TestTimer/countDown";
import { current } from "@reduxjs/toolkit";
import { setQuestionNumber } from "../redux/student/studentSlice";
import TestTermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import { TERMS_AND_CONDITIONS, ASKIITIAN_TERMS_AND_CONDITIONS } from "../utils/constants/constants";
import ImageQuestionStep from "./ImageTypeQuestion/QuestionImage";
import { updateAnswerIndex, resetAnswerStatus } from "../redux/student/studentSlice";
import updateUserResponse from "../api/useUpdateUserResponse";
import Lottie from 'lottie-react';
import animationData from '../assets/Animation/loading_animation.json';

const MultiStepForm = () => {
  const [pendingApiData, setPendingApiData] = useState( null );
  const [pendingApiDataMultiChoice, setPendingApiDataMultiChoice] = useState( null );
  const [pendingApiDataDropDown, setPendingApiDataDropDown] = useState( null );
  const [pendingApiTextInput, setPendingApiTextInput] = useState( null );
  const [pendingApiNumberInput, setPendingApiNumberInput] = useState( null );
  const [pendingApiUploadFile, setPendingApiUploadFile] = useState( null );
  const [pendingApiEmailInput, setPendingApiEmailInput] = useState( null );
  const [pendingApiVerification, setPendingApiVerification] = useState( null );
  const [pendingStateQuestion, setPendingStateQuestion] = useState( null );

  const [isTimeCompleted, setIsTimeCompleted] = useState( false );
  const [isModalOpen, setIsModalOpen] = useState( false );

  const handleTimerComplete = () => {
    setIsTimeCompleted( true );
  };

  const [dynamicQuestions, setDynamicQuestions] = useState( [] );

  const [textAnswers, setTextAnswers] = useState( [] );

  //const [currentDynamicStep, setCurrentDynamicStep] = useState( 0 );
  const { client_name, id, grade } = useParams();

  const [addedFollowUps, setAddedFollowUps] = useState( [] );
  const [email, setEmail] = useState( '' );
  const [name, setName] = useState( '' );
  const [phoneNumber, setPhoneNumber] = useState( '' );
  const [showAdvertisement, setShowAdvertisement] = useState( false );

  const dispatch = useDispatch();
  const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
  const isConsentForm = useSelector( state => state.consentForm.isConsentForm )
  const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
  const isLoadingScreen = useSelector( state => state.loadingScreen.isLoadingScreen );

  const isRequiredQuestionsAttempted = useSelector( state => state.requiredQuestionsAttempted.isRequiredQuestionsAttempted )

  const isTest = ( client_name === "icat" || client_name === "jc" ) && grade !== null && window.location.href.includes( 'test' )

  const student_grade_local_storage = localStorage.getItem( 'student_grade' );
  //console.log( "questionNumberCircle2", questionNumber );


  const resetAdvertisement = () => {
    setShowAdvertisement( false );
  };

  const handleProceedToQuiz = () => {
    dispatch( setIsConsentForm( false ) );
    localStorage.setItem( 'isConsentForm', false )
  };

  const navigate = useNavigate();

  useEffect( () => {
    if ( dynamicData[client_name] ) {
      document.title = dynamicData[client_name].document_title;
      document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
    }
  }, [client_name, dynamicData] );

  const terms_and_conditions = client_name === "icat" ? ASKIITIAN_TERMS_AND_CONDITIONS : TERMS_AND_CONDITIONS;
  const student_route = dynamicData[client_name].student_route || 0;
  const parent_route = dynamicData[client_name].parent_route;
  const college_route = dynamicData[client_name].college_route;
  const working_professional_route = dynamicData[client_name].working_professional_route;
  const school_registration_route = dynamicData[client_name].school_registration_route;
  const student_registration_route = dynamicData[client_name].student_registration_route;
  const test_verification_mobile = dynamicData[client_name].test_verification_mobile;
  const proceedToTest = dynamicData[client_name].proceed_to_test;

  const isCouponCodeVerification = dynamicData[client_name].coupon_code_verification;

  const is_mobile_verification = dynamicData[client_name].is_mobile_verification;
  const is_email_verification = dynamicData[client_name].is_email_verification;
  const is_Loading_Screen = dynamicData[client_name].is_Loading_Screen;
  const showTimer = dynamicData[client_name].show_test_timer;
  const isPagination = dynamicData[client_name].is_pagination;
  const isQuestionNavigation =
    dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );

  const testTimeInSeconds = dynamicData[client_name]?.test_time_in_seconds

  useEffect( () => {
    if ( id === student_route ) {
      dispatch( setIsStudent( true ) );
      dispatch( setIsParent( false ) );
    } else if ( id === parent_route ) {
      dispatch( setIsParent( true ) );
      dispatch( setIsStudent( false ) );
    } else if ( id === school_registration_route ) {
      dispatch( setIsSchoolRegistration( true ) );
      dispatch( setIsStudentRegistration( false ) );
    } else if ( id === student_registration_route ) {
      dispatch( setIsStudentRegistration( true ) );
      dispatch( setIsSchoolRegistration( false ) );
    } else {
      console.log( 'working' )
    }
  }, [id] )

  useEffect( () => {
    let productCode = "";
    if ( id === college_route ) {
      productCode = "COLLEGE_SURVEY";
    } else if ( id === working_professional_route ) {
      productCode = "PROFESSIONAL_SURVEY";
    }
    localStorage.setItem( 'product_code', productCode );
  }, [id] )

  const { data, isLoading, isError } = useFetchQuestion( id );

  const form_type = data?.form_type;
  const { response: updateUserResponseData, isLoading: updateUserResponseLoading, error: updateUserResponseError, updateResponse } = updateUserResponse();


  const [userAssessmentResponseID, setUserAssessmentResponseID] = useState(
    localStorage.getItem( 'userAssessmentResponseID' )
  );


  useEffect( () => {
    if ( data?.questions ) {
      setDynamicQuestions( [...data.questions] );
    }
  }, [data] );

  //useEffect( () => {
  //  const storedTextAnswers = localStorage.getItem( `textAnswers_${ id }` );
  //  if ( storedTextAnswers ) {
  //    setTextAnswers( JSON.parse( storedTextAnswers ) );
  //  } else if ( data?.questions ) {
  //    setTextAnswers( Array( data.questions.length ).fill( '' ) );
  //  }
  //}, [data, id] );


  const userId = localStorage.getItem( 'student_roll_number' ) || 0;
  const is_completed = localStorage.getItem( 'is_completed' ) || 1;

  const { response, error, CreateUserAssessmentResponse } = useCreateUserAssessmentResponse()

  useEffect( () => {
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );

    if ( userAssessmentResponseID === null && form_type === "survey" ) {
      localStorage.clear();
      CreateUserAssessmentResponse( userId, id, is_completed );
    } else if ( userAssessmentResponseID === null && form_type === "test" ) {
      CreateUserAssessmentResponse( userId, id, is_completed );
    } else if ( userAssessmentResponseID !== null && form_type === "survey" ) {
      localStorage.clear();
      CreateUserAssessmentResponse( userId, id, is_completed );
    } else if ( userAssessmentResponseID !== null && form_type === "test" ) {
    }
  }, [data] )

  useEffect( () => {
    if ( response )
      if ( userAssessmentResponseID === null && form_type === "survey" ) {
        localStorage.setItem( 'userAssessmentResponseID', response.id );
      } else if ( userAssessmentResponseID === null && form_type === "test" ) {
        localStorage.setItem( 'userAssessmentResponseID', response.id );
      } else if ( userAssessmentResponseID !== null && form_type === "survey" ) {
        localStorage.setItem( 'userAssessmentResponseID', response.id );
      } else if ( userAssessmentResponseID !== null && form_type === "test" ) {
      }
    setUserAssessmentResponseID( localStorage.getItem( 'userAssessmentResponseID' ) )
  }, [response] )

  useEffect( () => {
    if ( userAssessmentResponseID !== null ) {
      const storedTextAnswers = localStorage.getItem( `textAnswers_${ id }_${ userAssessmentResponseID }` );
      if ( storedTextAnswers ) {
        setTextAnswers( JSON.parse( storedTextAnswers ) );
      } else if ( data?.questions ) {
        setTextAnswers( Array( data.questions.length ).fill( '' ) );
      }
    }
  }, [data, id, userAssessmentResponseID] );

  useEffect( () => {
    if ( !userAssessmentResponseID ) {
      // No ID in localStorage, make API call
      if ( response?.id && localStorage.getItem( 'userAssessmentResponseID' ) === null ) {
        localStorage.setItem( 'userAssessmentResponseID', response.id );
        setUserAssessmentResponseID( response.id );
      }
    }
    localStorage.setItem( 'testID', id );
  }, [response, id, userAssessmentResponseID] );

  // Function to get the stored currentDynamicStep value from localStorage
  const getStoredCurrentDynamicStep = () => {
    const storedStep = localStorage.getItem( `currentDynamicStep_${ id }` );
    return storedStep ? parseInt( storedStep, 10 ) : 0;
  };

  //const [currentDynamicStep, setCurrentDynamicStep] = useState( getStoredCurrentDynamicStep() );
  const [currentDynamicStep, setCurrentDynamicStep] = useState( questionNumber );

  useEffect( () => {
    setCurrentDynamicStep( questionNumber );
  }, [questionNumber] );

  //useEffect( () => {
  //  const storedQuestionNumber = localStorage.getItem( 'questionNumber' ) || 0;
  //  setCurrentDynamicStep( storedQuestionNumber );
  //}, [id] );

  useEffect( () => {
    // Update the stored currentDynamicStep whenever it changes
    dispatch( setQuestionNumber( currentDynamicStep ) );
  }, [currentDynamicStep] );

  useEffect( () => {
    // Check if textAnswers is non-empty before updating local storage
    if ( textAnswers.length > 0 && userAssessmentResponseID !== null ) {
      localStorage.setItem( `textAnswers_${ id }_${ userAssessmentResponseID }`, JSON.stringify( textAnswers ) );
    }
  }, [textAnswers, id, userAssessmentResponseID] );

  const [createUserQuestionResponse, Submitresponse, fail] = useCreateUserQuestionResponse();

  const [dateOfbirth, setDateOfbirth] = useState( Array( 10 ).fill( '' ) );

  const handleSingleChoiceSelect = ( option ) => {

    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = option;
    setTextAnswers( newTextAnswers );

    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
    const selectedOptionId = option?.id;

    setPendingApiData( {
      currentQuestionID,
      userAssessmentResponseID,
      selectedOptionId: [selectedOptionId]
    } );

    const updatedDynamicQuestions = [...dynamicQuestions];
    const prevFollowUpIndex = addedFollowUps[currentDynamicStep];

    // If there was a previous follow-up question, remove it
    if ( prevFollowUpIndex !== undefined ) {
      updatedDynamicQuestions.splice( prevFollowUpIndex, 1 );
      setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: undefined } );
    }

    // If the new option has a follow-up question, add it
    if ( option?.follow_up_question ) {
      updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, option.follow_up_question );
      setAddedFollowUps( { ...addedFollowUps, [currentDynamicStep]: currentDynamicStep + 1 } );

    }
    setDynamicQuestions( updatedDynamicQuestions );
  };

  const handlesingleChoiceClearAll = ( option ) => {
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = null; // Clear the selected option
    setTextAnswers( newTextAnswers );

    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    // Send an empty array to the API to indicate that nothing is selected
    setPendingApiData( {
      currentQuestionID,
      userAssessmentResponseID,
      selectedOptionId: [],
    } );

    const selectedOptionId = option?.id;

    if ( selectedOptionId?.length > 0 ) {
      // Dispatch updateAnswerIndex if selectedOptionId is not empty
      dispatch( updateAnswerIndex( currentDynamicStep ) );
    } else {
      // Dispatch resetAnswerStatus if selectedOptionId is empty
      dispatch( resetAnswerStatus( currentDynamicStep ) );
    }

    createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
    setPendingApiData( null ); // Clear pendingApiData
  };

  const handleSingleChoiceNext = () => {
    // Make API call using pendingApiData if it exists
    if ( pendingApiData ) {
      const { currentQuestionID, userAssessmentResponseID, selectedOptionId } = pendingApiData;

      if ( selectedOptionId.length > 0 ) {
        // Dispatch updateAnswerIndex if selectedOptionId is not empty
        dispatch( updateAnswerIndex( currentDynamicStep ) );
      } else {
        // Dispatch resetAnswerStatus if selectedOptionId is empty
        dispatch( resetAnswerStatus( currentDynamicStep ) );
      }

      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId );
      setPendingApiData( null ); // Clear pendingApiData
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };

  const handleMultipleChoiceSelect = ( selectedOptionIds ) => {
    console.log( selectedOptionIds.length )

    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = selectedOptionIds;
    setTextAnswers( newTextAnswers );

    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    // Populate pendingApiDataMultiChoice
    setPendingApiDataMultiChoice( {
      currentQuestionID,
      userAssessmentResponseID,
      selectedOptionIds,
    } );



    // This will be the updated list of dynamic questions after insertion/removal of follow-ups
    let updatedDynamicQuestions = [...dynamicQuestions];

    // Identify the follow-up questions that are currently in the dynamicQuestions list
    const currentFollowUps = updatedDynamicQuestions.slice( currentDynamicStep + 1 )
      .filter( q => q.isFollowUp ); // Assuming isFollowUp is a property you set when adding follow-ups

    // Remove all current follow-up questions related to the current step
    updatedDynamicQuestions = updatedDynamicQuestions.filter( q => !q.isFollowUp || !currentFollowUps.includes( q ) );

    // Now, determine which follow-up questions to add based on the newly selected options
    selectedOptionIds.forEach( id => {
      const option = dynamicQuestions[currentDynamicStep].options.find( o => o.id === id );
      if ( option && option.follow_up_question ) {
        // Mark the question as a follow-up for easier identification
        const followUpQuestion = { ...option.follow_up_question, isFollowUp: true };
        updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, followUpQuestion );
      }
    } );

    // Update the dynamicQuestions state with the new questions list
    setDynamicQuestions( updatedDynamicQuestions );
  };

  const handleMultiChoiceNext = () => {
    if ( pendingApiDataMultiChoice ) {
      const { currentQuestionID, userAssessmentResponseID, selectedOptionIds } = pendingApiDataMultiChoice;
      if ( selectedOptionIds.length > 0 ) {
        // Dispatch updateAnswerIndex if selectedOptionId is not empty
        dispatch( updateAnswerIndex( currentDynamicStep ) );
      } else if ( selectedOptionIds.length === 0 ) {
        // Dispatch resetAnswerStatus if selectedOptionId is empty
        dispatch( resetAnswerStatus( currentDynamicStep ) );
      }
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionIds );
      setPendingApiDataMultiChoice( null );
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };

  const handleDropdownSelect = ( selectedValues ) => {

    const normalizedSelectedValues = Array.isArray( selectedValues ) ? selectedValues : [selectedValues];

    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = selectedValues;
    setTextAnswers( newTextAnswers );

    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    setPendingApiDataDropDown( {
      currentQuestionID,
      userAssessmentResponseID,
      selectedValues
    } );

    // This will be the updated list of dynamic questions after insertion/removal of follow-ups
    let updatedDynamicQuestions = [...dynamicQuestions];

    // Identify the follow-up questions that are currently in the dynamicQuestions list
    const currentFollowUps = updatedDynamicQuestions.slice( currentDynamicStep + 1 )
      .filter( q => q.isFollowUp ); // Assuming isFollowUp is a property you set when adding follow-ups

    // Remove all current follow-up questions related to the current step
    updatedDynamicQuestions = updatedDynamicQuestions.filter( q => !q.isFollowUp || !currentFollowUps.includes( q ) );

    // Now, determine which follow-up questions to add based on the newly selected options
    normalizedSelectedValues.forEach( id => {
      const option = dynamicQuestions[currentDynamicStep].options.find( o => o.id === id );
      if ( option && option.follow_up_question ) {
        // Mark the question as a follow-up for easier identification
        const followUpQuestion = { ...option.follow_up_question, isFollowUp: true };
        updatedDynamicQuestions.splice( currentDynamicStep + 1, 0, followUpQuestion );
      }
    } );

    // Update the dynamicQuestions state with the new questions list
    setDynamicQuestions( updatedDynamicQuestions );
  };

  const handleCourseSelect = ( answer, currentDynamicStep ) => {
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = answer;
    setTextAnswers( newTextAnswers );

    // Get the current question ID
    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    setPendingApiDataDropDown( {
      currentQuestionID,
      userAssessmentResponseID,
      textResponse: answer
    } );
  };

  const handleDropDownNext = () => {
    // Make API call using pendingApiDataMultiChoice if it exists
    if ( pendingApiDataDropDown ) {
      const { currentQuestionID, userAssessmentResponseID, selectedValues, textResponse } = pendingApiDataDropDown;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedValues, textResponse );
      setPendingApiDataDropDown( null );
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };

  const handleTextAnswerChange = ( answer, currentDynamicStep ) => {
    // Update the local state for text answers
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = answer;
    setTextAnswers( newTextAnswers );

    // Get the current question ID
    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    // Populate pendingApiTextInput
    setPendingApiTextInput( {
      currentQuestionID,
      userAssessmentResponseID,
      textResponse: answer
    } );
  };

  const handleTextNext = () => {
    // Make API call using pendingApiTextInput if it exists
    if ( pendingApiTextInput ) {
      const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiTextInput;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
      setPendingApiTextInput( null );  // Clear pendingApiTextInput
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };

  const handleNameAnswerChange = ( answer, currentDynamicStep ) => {
    // Update the local state for text answers
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = answer;
    setTextAnswers( newTextAnswers );

    // Get the current question ID
    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    // Populate pendingApiTextInput
    setPendingApiTextInput( {
      currentQuestionID,
      userAssessmentResponseID,
      textResponse: answer
    } );
  };

  const handleNameNext = () => {
    // Make API call using pendingApiTextInput if it exists
    if ( pendingApiTextInput ) {
      const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiTextInput;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
      localStorage.setItem( 'user_name', textResponse );
      setPendingApiTextInput( null );  // Clear pendingApiTextInput
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };


  const handleNumberTextAnswerChange = ( answer, currentDynamicStep ) => {
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = answer;
    setTextAnswers( newTextAnswers );

    // Get the current question ID
    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    // Populate pendingApiNumberInput
    setPendingApiNumberInput( {
      currentQuestionID,
      userAssessmentResponseID,
      numberTextResponse: answer
    } );
  };

  const handleNumberTextNext = () => {
    // Make API call using pendingApiNumberInput if it exists
    if ( pendingApiNumberInput ) {
      const { currentQuestionID, userAssessmentResponseID, numberTextResponse } = pendingApiNumberInput;

      // Remove the first two digits from numberTextResponse
      const numberForVerification = numberTextResponse.slice( 3 );

      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], numberTextResponse );
      setPhoneNumber( numberForVerification );
      setPendingApiNumberInput( null );  // Clear pendingApiNumberInput
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };


  const handleEmailType = ( answer, currentDynamicStep ) => {
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = answer;
    setTextAnswers( newTextAnswers );

    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    setPendingApiEmailInput( {
      currentQuestionID,
      userAssessmentResponseID,
      textResponse: answer
    } );
  };

  const handleEmailTypeNext = () => {
    if ( pendingApiEmailInput ) {
      const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingApiEmailInput;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
      setEmail( textResponse )
      setPendingApiEmailInput( null );
      localStorage.setItem( 'user_email', textResponse );
      //setEmail( textResponse )
      //console.log( email );
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  }

  const handleBack = () => {
    if ( currentDynamicStep === 0 ) return null;
    setCurrentDynamicStep( currentDynamicStep - 1 );
  };

  const handleUploadFileChange = ( option ) => {

    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = option;
    setTextAnswers( newTextAnswers );

    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;
    const selectedOptionId = option?.id;

    // Store this data for later submission to the API
    setPendingApiUploadFile( {
      currentQuestionID,
      userAssessmentResponseID,
      selectedOptionId: [selectedOptionId]
    } );
  };


  const handleNextUpload = ( data ) => {
    // Logic to send data to API
    if ( pendingApiUploadFile ) {
      const { currentQuestionID, userAssessmentResponseID, selectedOptionId } = pendingApiUploadFile;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, selectedOptionId, data );
      setPendingApiUploadFile( null );
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };

  const handleVerificationType = ( answer, stepIndex ) => {
    if ( phoneNumber ) {
      setTextAnswers( phoneNumber );
    } else {
      let newTextAnswers = [...textAnswers];
      newTextAnswers[stepIndex] = answer;
      setTextAnswers( newTextAnswers );
    }

    const currentQuestionID = 4;

    setPendingApiVerification( {
      currentQuestionID,
      userAssessmentResponseID,
      numberTextResponse: answer
    } );
  };

  const handleVerificationTypeNext = () => {
    if ( pendingApiVerification ) {
      const { currentQuestionID, userAssessmentResponseID, numberTextResponse } = pendingApiVerification;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], numberTextResponse );
      setEmail( numberTextResponse )
      setPhoneNumber( numberTextResponse )
      setPendingApiVerification( null );
    }
  };

  const handleStateAnswerChange = ( answer, currentDynamicStep ) => {
    // Update the local state for text answers
    let newTextAnswers = [...textAnswers];
    newTextAnswers[currentDynamicStep] = answer;
    setTextAnswers( newTextAnswers );

    // Get the current question ID
    const currentQuestionID = dynamicQuestions[currentDynamicStep].id;

    // Populate pendingApiTextInput
    setPendingStateQuestion( {
      currentQuestionID,
      userAssessmentResponseID,
      textResponse: answer
    } );
  };

  const handleStateNext = () => {
    // Make API call using pendingApiTextInput if it exists
    if ( pendingStateQuestion ) {
      const { currentQuestionID, userAssessmentResponseID, textResponse } = pendingStateQuestion;
      createUserQuestionResponse( currentQuestionID, userAssessmentResponseID, [], textResponse );
      setPendingStateQuestion( null );  // Clear pendingApiTextInput
    }
    setCurrentDynamicStep( currentDynamicStep + 1 );
  };

  const totalSteps = dynamicQuestions.length;
  const completedProgress = Math.round( ( currentDynamicStep / dynamicQuestions.length ) * 100 );

  const renderQuestionComponent = ( currentDynamicStep, question ) => {
    const questionToRender = dynamicQuestions[currentDynamicStep];
    //console.log( localStorage.getItem( 'examination_mode' ) );
    switch ( questionToRender?.input_type ) {
      case "single_choice_question":
        return (
          <QuestionStep
            question={ questionToRender }
            selected={ textAnswers[currentDynamicStep] }
            onSelect={ handleSingleChoiceSelect }
            onNext={ handleSingleChoiceNext }
            onBack={ handleBack }
            onClearAll={ handlesingleChoiceClearAll }
          />
        );
      case "multiple_choice_question":
        return (
          <MultiChoiceStep
            question={ questionToRender }
            selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
            onSelect={ handleMultipleChoiceSelect }
            onNext={ handleMultiChoiceNext }
            onBack={ handleBack }
          />
        );
      case "drop_down_multiselect_question":
        return (
          <DropDownStepMultipleSelect
            question={ questionToRender }
            selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
            onSelect={ handleDropdownSelect }
            onNext={ handleDropDownNext }
            onBack={ handleBack }
          />
        );
      case "drop_down_singleselect_question":
        return (
          <DropDownStepSingleSelect
            question={ questionToRender }
            selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
            onSelect={ handleDropdownSelect }
            onNext={ handleDropDownNext }
            onBack={ handleBack }
            courseName={ ( answer ) => handleCourseSelect( answer, currentDynamicStep ) }
            selectedCourseName={ textAnswers[currentDynamicStep] }
          />
        );
      case "text_area_question":
        return (
          <TextInputStep
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleTextNext }
            onBack={ handleBack }
          />
        );
      case "number_input_question":
        return (
          <NumberInputStep
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleNumberTextAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleNumberTextNext }
            onBack={ handleBack }
          />
        );
      case "upload_type_question":
        return (
          <MultiChoiceStepLink
            question={ questionToRender }
            selected={ textAnswers[currentDynamicStep] }
            onSelect={ handleUploadFileChange }
            onNextUpload={ handleNextUpload }
            onBack={ handleBack }
          />
        );
      case "email_type_question":
        return (
          <EmailTypeStep
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleEmailType( answer, currentDynamicStep ) }
            onNext={ handleEmailTypeNext }
            onBack={ handleBack }
          />
        )
      case "phone_number_input_question":
        return (
          <PhoneNumberInputStep
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleNumberTextAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleNumberTextNext }
            onBack={ handleBack }
          />
        )
      case "states_selection_question":
        return (
          <StateSelectionQuestion
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleStateAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleStateNext }
            onBack={ handleBack }
          />
        )
      case "name_input_question":
        return (
          <NameInputStep
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleNameAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleNameNext }
            onBack={ handleBack }
          />
        );
      case "date_input_type":
        return (
          <DobInputType
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleTextNext }
            onBack={ handleBack }
          />
        );
      case "number_and_text_input_type":
        return (
          <FlexibleInputStep
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleTextAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleTextNext }
            onBack={ handleBack }
          />
        );
      case "indian_state_selection_type":
        return (
          <IndianStateSelectionQuestion
            question={ questionToRender }
            answer={ textAnswers[currentDynamicStep] }
            onAnswerChange={ ( answer ) => handleStateAnswerChange( answer, currentDynamicStep ) }
            onNext={ handleStateNext }
            onBack={ handleBack }
          />
        );
      case "nudge":
        return (
          <AdvertiseMentModal
            onNext={ () => setCurrentDynamicStep( currentDynamicStep + 1 ) }
            resetAdvertisement={ resetAdvertisement }
            nudge={ dynamicQuestions[currentDynamicStep]?.nudge }
            onBack={ () => setCurrentDynamicStep( currentDynamicStep - 1 ) }
          />
        );
      //case "single_choice_question":
      //  return (
      //    <ImageQuestionStep
      //      question={ imageQuestion }
      //      selectedOptionsFromParent={ textAnswers[currentDynamicStep] }
      //      onSelect={ handleMultipleChoiceSelect }
      //      onNext={ handleMultiChoiceNext }
      //      onBack={ handleBack }
      //    />
      //  );
      default:
        return <div>Unknown question type</div>;
    }
  };

  const renderQuestionBatch = ( startIndex, batchSize ) => {
    return dynamicQuestions.slice( startIndex, startIndex + batchSize ).map( ( question, index ) => {
      return renderQuestionComponent( startIndex + index, question );
    } );
  };

  const handleNextBatch = ( startIndex, batchSize ) => {
    // Update the state to move to the next batch
    setCurrentDynamicStep( startIndex + batchSize );
    // You can add additional logic here if needed
  };

  const renderQuestions = ( startIndex, batchSize ) => {
    return (
      <div>
        {
          isPagination ?
            <>
              { renderQuestionBatch( startIndex, batchSize ) }
              <button onClick={ () => handleNextBatch( startIndex + batchSize ) }>Next</button>
            </>
            :
            renderQuestionComponent( currentDynamicStep, 0 )
        }
      </div>
    );
  };

  const handleFinishAttempt = () => {
    if ( isRequiredQuestionsAttempted ) {
      updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
      dispatch( setIsLoadingScreen( true ) );
      localStorage.setItem( 'isLoadingScreen', true )
      setIsModalOpen( false );
    } else {
      notification.error( {
        message: 'Unable to submit!',
        description: 'Please attempt all the required questions to submit the test.',
      } );
    }
  }

  //const handleFinishAttempt = () => {
  //  updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
  //  dispatch( setIsLoadingScreen( true ) );
  //  localStorage.setItem( 'isLoadingScreen', true )
  //  setIsModalOpen( false );
  //}

  return (
    <div className="flex justify-center items-center">
      <>
        { isLoading ?
          <div className="flex items-center justify-center h-screen">
            <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
          </div>
          :
          <>
            <div className="container-fluid">
              <div className="row row-height">
                {
                  dynamicData[client_name].is_left_sideBar_visible ? <LeftSideBar data={ data } /> : ""
                }
                { isConsentForm && (
                  <TestTermsAndConditions onSubmit={ handleProceedToQuiz } link={ terms_and_conditions } />
                ) }
                <div className={ `${ dynamicData[client_name].is_left_sideBar_visible ? 'col-xl-8 col-lg-8 content-right' : 'flex justify-center p-10' }` } id="start">
                  <>
                    <div className={ `${ isQuestionNavigation ? "w-3/4 h-full" : "w-full xl:w-3/4 lg:w-3/4 sm:w-full" } ${ showTimer ? 'mt-5' : !isLoadingScreen && !isFormSubmitted && isTest ? 'mt-[30%]' : 'mt-5' }` }>
                      <div id="top-wizard">
                        {
                          !isFormSubmitted && isQuestionNavigation && showTimer && !isLoadingScreen ?
                            <>
                              <div className="flex justify-center gap-4 mb-3">
                                <TestTimer testID={ id } onComplete={ handleTimerComplete } testTimeInSeconds={ testTimeInSeconds } />
                              </div>
                            </>
                            :
                            <></>
                        }
                        {
                          ( client_name === "icat" || client_name === "jc" ) && grade !== null ?
                            (
                              !isFormSubmitted && !isLoadingScreen ?
                                <>
                                  <div className="mb-5">
                                    <ProgressBar completed={ completedProgress } maxCompleted={ 100 } bgColor="#3F52FD" isLabelVisible={ false } height={ 15 } style={ {
                                      display: 'content',
                                      alignItems: 'initial',
                                    } } />
                                  </div>
                                </>
                                : null
                            )
                            :
                            (
                              !isFormSubmitted ?
                                <>
                                  <ProgressBar completed={ completedProgress } maxCompleted={ 100 } bgColor="#3F52FD" isLabelVisible={ false } height={ 15 } style={ {
                                    display: 'content',
                                    alignItems: 'initial',
                                  } } />
                                  {/*<p className="text-small font-semibold mt-2">
                                    <span style={ { color: '#3F52FD' } }>{ completedProgress }%</span> out of <span style={ { color: '#3F52FD' } }>100%</span> completed.
                                  </p>*/}
                                </>
                                : null
                            )
                        }
                      </div>
                      <div className="p-8 xl:p-8 lg:p-8 sm:p-4">
                        <div>
                          { isTest ? (
                            !isFormSubmitted ? (
                              !isLoadingScreen ? (
                                currentDynamicStep < dynamicQuestions.length ? (
                                  <>
                                    { renderQuestions( currentDynamicStep, 5 ) }
                                  </>
                                )
                                  : (
                                    <>
                                      <div className="bg-white p-8 rounded-lg w-full flex flex-col items-center">

                                        <p className="text-2xl font-semibold mb-1">Are you sure you want to submit the test?</p>
                                        <p className="text-lg font-bold mb-3">( once submitted, answers cannot be altered )</p>
                                        <Image src="https://i.ibb.co/nDGq8Wt/illustration-test-completed.png" alt="comltedt test" preview={ false } />
                                        <div className="flex gap-10 mt-3">
                                          <button className="bg-white-500 text-black font-bold py-3 px-6 rounded border-2 border-black" type="button" onClick={ handleBack }>
                                            Go Back
                                          </button>
                                          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline-blue" type="button" onClick={ handleFinishAttempt }>
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )
                              )
                                :
                                <Review data={ data } />
                            )
                              :
                              (
                                test_verification_mobile ?
                                  <MobileVerification
                                    onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                    onNext={ handleVerificationTypeNext }
                                    onBack={ handleBack }
                                    phoneNumber={ phoneNumber }
                                    isTimeCompleted={ isTimeCompleted }
                                  /> :
                                  <EmailVerification
                                    onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                    onNext={ handleVerificationTypeNext }
                                    onBack={ handleBack }
                                    userEmail={ email }
                                    isTimeCompleted={ isTimeCompleted }
                                  />
                              )
                          ) :
                            (
                              !isFormSubmitted ? (
                                currentDynamicStep < dynamicQuestions.length ? (
                                  <>
                                    { renderQuestions( currentDynamicStep, 5 ) }
                                  </>
                                )
                                  : (
                                    is_mobile_verification ?
                                      <>
                                        <MobileVerification
                                          onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                          onNext={ handleVerificationTypeNext }
                                          onBack={ handleBack }
                                          phoneNumber={ phoneNumber }
                                          isTimeCompleted={ isTimeCompleted }
                                        />
                                      </> :
                                      is_email_verification ?
                                        <EmailVerification
                                          onAnswerChange={ ( currentQuestionText, answer ) => handleVerificationType( currentQuestionText, answer, currentDynamicStep ) }
                                          onNext={ handleVerificationTypeNext }
                                          onBack={ handleBack }
                                          userEmail={ email }
                                          isTimeCompleted={ isTimeCompleted }
                                        />
                                        :
                                        (
                                          is_Loading_Screen ?
                                            <Review data={ data } /> :
                                            proceedToTest ?
                                              navigate( `/${ client_name }/test/${ student_grade_local_storage }/instructions` ) :
                                              navigate( `/${ client_name }/thank_you` )
                                        )
                                  )
                              ) : (
                                isCouponCodeVerification ?
                                  navigate( `/${ client_name }/verification/coupon_code` )
                                  : is_Loading_Screen ?
                                    <Review data={ data } /> :
                                    proceedToTest ?
                                      navigate( `/${ client_name }/test/${ student_grade_local_storage }/instructions` ) :
                                      navigate( `/${ client_name }/thank_you` )
                              )
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </>
        }
      </>
    </div>
  );
};

export default MultiStepForm;