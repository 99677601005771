import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const EmailTypeStep = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
    const [isAnswerFilled, setIsAnswerFilled] = useState( false );
    const [email, setEmail] = useState( "" );

    const handleAnswerChange = ( value ) => {
        onAnswerChange( value );
        setEmail( value );
        setIsAnswerFilled( value.trim() !== "" );

        if ( question.text.includes( "Please provide coordinator's email id" ) ) {
            localStorage.setItem( 'contact_person_email', value );
        } else if ( question.text.includes( "Please provide your email id" ) ) {
            localStorage.setItem( 'studentEmail', value );
        } else if ( question.text.includes( "Please provide your email address so that you don’t lose progress" ) ) {
            localStorage.setItem( 'studentEmail', value );
        }
    };

    useEffect( () => {
        setEmail( answer );
    }, [question] );

    useEffect( () => {
        if ( answer ) {
            setIsAnswerFilled( true )
            setEmail( answer );
        } else {
            setIsAnswerFilled( false )
        }
    }, [question, answer] )

    const isValidEmail = ( value ) => {
        const regex = /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/;
        return regex.test( value );
    };

    const { client_name } = useParams();

    const isPagination = dynamicData[client_name].is_pagination;
    const is_question_navigation = dynamicData[client_name].is_question_navigation &&
        window.location.href.includes( 'test' );

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <QuestionAndDescription question={ question } />
                    <div>
                        <input
                            className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                            style={ { resize: "none", textAlign: "left", } }
                            value={ email }
                            onChange={ ( e ) => {
                                //setEmail( e.target.value );
                                handleAnswerChange( e.target.value );
                            } }
                            placeholder={ question?.placeholder_text }
                            onKeyDown={ handleKeyDown }
                        />
                    </div>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    is_question_navigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mt-4">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                                Back
                            </button>
                            <button
                                className={ `px-4 py-2 rounded-md text-white ${ !isValidEmail( email ) ? "bg-blue-300" : "bg-blue-500" }` }
                                onClick={ () => {
                                    if ( isValidEmail( email ) ) {
                                        onNext();
                                    }
                                } }
                                style={ !isValidEmail( email ) ? { cursor: "not-allowed" } : { cursor: "pointer" } }
                            >
                                Next
                            </button>
                        </div>
            }
        </>
    );
};

export default EmailTypeStep;
