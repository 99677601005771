import React, { useEffect } from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, Link } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import ThankyouNnit from './ThankYouPageNniit';
import ThankyouTTM from './ThankYouPageTtm';
import { ThankyouAskIIt, ThankyouAskIItTest } from './ThankYouAskIit';
import { ThankYouJc, ThankYouJcTest } from './ThankYouJitinChawla';
import { useDispatch } from 'react-redux';
import { setIsFormSubmitted } from '../../redux/student/studentSlice';
import { ThankYouInternshala } from './ThankYouInternshala';
import { ThankYouJenNext } from './ThankYouJenNext';

const ThankYouPage = () => {
    const dispatch = useDispatch();
    const { client_name, grade } = useParams()
    console.log( client_name )

    useEffect( () => {
        dispatch( setIsFormSubmitted( true ) )
        if ( dynamicData[client_name] ) {
            document.title = dynamicData[client_name].document_title;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
        }
    }, [client_name, dynamicData] );

    return (
        <div className="container-fluid">
            <div className="row row-height">
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div id="wizard_container">
                            <div className="flex flex-col items-center mt-20 space-y-8 sm:mt-10 ">
                                {/*{ client_name === "nniit" && (
                                    <ThankyouNnit studentName="Nnit Student" />
                                ) }
                                { client_name === "ttm" && (
                                    <ThankyouTTM studentName="ttm student" />
                                ) }
                                { client_name === "icat" && !grade ? (
                                    <ThankyouAskIIt />
                                ) : (
                                    <ThankyouAskIItTest />
                                ) }*/}
                                {/*{ client_name === "nniit"
                                    ? <ThankyouNnit studentName="Nnit Student" />
                                    : client_name === "ttm"
                                        ? <ThankyouTTM studentName="ttm student" />
                                        : client_name === "icat" && !grade
                                            ? <ThankyouAskIIt />
                                            : <ThankyouAskIItTest />

                                }*/}
                                { client_name === "JenNextMentors" ?
                                    <ThankYouJenNext /> :
                                    client_name === "DSAssessment" ?
                                        <ThankYouInternshala /> :
                                        client_name === "nniit"
                                            ? <ThankyouNnit studentName="Nnit Student" />
                                            : client_name === "ttm"
                                                ? <ThankyouTTM studentName="ttm student" />
                                                : client_name === "icat"
                                                    ? !grade ? <ThankyouAskIIt /> : <ThankyouAskIItTest />
                                                    : client_name === "jc"
                                                        ? !grade ? <ThankYouJc /> : <ThankYouJcTest />
                                                        : null
                                }
                            </div>
                        </div>
                    </>
                </div>

            </div>
        </div>
    );
};

export default ThankYouPage
