import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import '../../styles.css';
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";

const NameInputStep = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
  const [isAnswerFilled, setIsAnswerFilled] = useState( false );
  const [firstName, setFirstName] = useState( "" );

  useEffect( () => {
    setFirstName( answer );
  }, [question] );

  useEffect( () => {
    if ( answer ) {
      setIsAnswerFilled( true )
      setFirstName( answer );
    } else {
      setIsAnswerFilled( false )
    }
  }, [question, answer] )


  const handleKeyDownName = ( e ) => {
    // Prevent input of numeric values and special characters (except space)
    const regex = /^[A-Za-z\s]+$/;
    if ( !regex.test( e.key ) ) {
      e.preventDefault();
    }
    if ( e.key === "Enter" ) {
      onNext();
    }
    TrackGoogleAnalyticsEvent( textTypeCategory, textTypeAction );
  };

  const handleAnswerChange = ( value ) => {
    const regex = /^[A-Za-z\s]*$/;
    if ( regex.test( value ) ) {
      onAnswerChange( value );
      setFirstName( value );
      setIsAnswerFilled( value.trim() !== "" );
    }
  };

  const { client_name } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <div>
            <input
              className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
              style={ { resize: "none", textAlign: "left" } }
              value={ firstName }
              onChange={ ( e ) => handleAnswerChange( e.target.value ) }
              onKeyDown={ handleKeyDownName }
              placeholder={ question?.placeholder_text }
            />
          </div>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          <div className="flex flex-row gap-2 justify-end mt-4">
            {
              question?.question_optional === "optional" && (
                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                  Skip
                </button>
              )
            }
            <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
              Back
            </button>
            <button
              className={ `px-4 py-2 rounded-md text-white ${ !isAnswerFilled ? "bg-blue-300" : "bg-blue-500" }` }
              onClick={ !isAnswerFilled ? null : onNext }
              style={ !isAnswerFilled ? { cursor: "not-allowed" } : { cursor: "pointer" } }
            >
              Next
            </button>
          </div>
      }
    </>
  );
};

export default NameInputStep;
