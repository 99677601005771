import React, { useState, useEffect, useRef } from "react";
import { Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { schoolData } from "../../helpers/data";

const SegmentInstructions = ( { isOpen, onOk } ) => {

    const { grade, client_name } = useParams()

    let className;

    if ( ( grade >= "5th" && grade <= "8th" ) ) {
        className = schoolData[client_name].primary;
    } else if ( grade === '9th' || grade === '10th' ) {
        className = schoolData[client_name].junior;
    } else if ( grade === '11th PCM' ) {
        className = schoolData[client_name].senior_pcm;
    } else if ( grade === '11th PCB' ) {
        className = schoolData[client_name].senior_pcb;
    } else {
        className = schoolData[client_name].primary
    }

    const heading_one = className.general_instructions.heading_one || "";
    const heading_two = className.general_instructions.heading_two || "";
    const heading_three = className.general_instructions.heading_three || "";
    const heading_four = className.general_instructions.heading_four || "";
    const paragraph_one = className.general_instructions.paragraph_one || "";
    const segment_1 = className.general_instructions.segment_1 || "";
    const segment_2 = className.general_instructions.segment_2 || "";
    const heading_zero = className.general_instructions.bold_heading_one || "";

    const segment_1_heading_one = className.segment_one_instructions.heading_one || "";
    const segment_1_heading_two = className.segment_one_instructions.heading_two || "";
    const segment_1_heading_three = className.segment_one_instructions.heading_three || "";
    const segment_1_heading_four = className.segment_one_instructions.heading_four || "";
    const segment_1_heading_five = className.segment_one_instructions.heading_five || "";
    const segment_1_heading_six = className.segment_one_instructions.heading_six || "";

    const segment_2_heading_one = className.segment_two_instructions.heading_one || "";
    const segment_2_heading_two = className.segment_two_instructions.heading_two || "";
    const segment_2_heading_three = className.segment_two_instructions.heading_three || "";
    const segment_2_heading_four = className.segment_two_instructions.heading_four || "";
    const segment_2_heading_five = className.segment_two_instructions.heading_five || "";

    const test_intructions_heading_one = className.test_Information.heading_one || "";
    const test_intructions_heading_two = className.test_Information.heading_two || "";
    const test_intructions_heading_three = className.test_Information.heading_three || "";
    const test_intructions_heading_four = className.test_Information.heading_four || "";

    const marking_scheme_section_a_heading = className.marking_scheme?.section_a?.heading || "";
    const marking_scheme_section_a_correct_attempt = className.marking_scheme?.section_a?.correct_attempt || "";
    const marking_scheme_section_a_incorrect_attempt = className.marking_scheme?.section_a?.incorrect_attempt || "";
    const marking_scheme_section_a_unattempted = className.marking_scheme?.section_a?.unattempted || "";

    const marking_scheme_section_b_heading = className.marking_scheme?.section_b?.heading || "";
    const marking_scheme_section_b_correct_attempt = className.marking_scheme?.section_b?.correct_attempt || "";
    const marking_scheme_section_b_incorrect_attempt = className.marking_scheme?.section_b?.incorrect_attempt || "";
    const marking_scheme_section_b_unattempted = className.marking_scheme?.section_b?.unattempted || "";

    const marking_scheme_section_c_heading = className.marking_scheme?.section_c?.heading || "";
    const marking_scheme_section_c_correct_attempt = className.marking_scheme?.section_c?.correct_attempt || "";
    const marking_scheme_section_c_incorrect_attempt = className.marking_scheme?.section_c?.incorrect_attempt || "";
    const marking_scheme_section_c_unattempted = className.marking_scheme?.section_c?.unattempted || "";

    return (
        <>
            <Modal open={ isOpen } onOk={ onOk } onCancel={ onOk } okButtonProps={ { style: { backgroundColor: 'blue', color: 'white', width: '80px' } } } centered width={ 1400 } height={ 800 } cancelButtonProps={ { style: { display: 'none' } } } closeIcon={ true }>
                <div className='text-center mb-5'>
                    <h1 className="text-2xl font-bold mb-4">Test Instructions</h1>
                </div>
                <p className="mb-6 text-base">{ test_intructions_heading_one } { grade }</p>
                <p className="mb-6 text-base">{ test_intructions_heading_two }</p>
                <p className="mb-6 text-base">{ test_intructions_heading_three }</p>
                <p className="mb-6 text-base">{ test_intructions_heading_four }</p>
                <div className='text-center mb-5'>
                    <h1 className="text-2xl font-bold mb-4">General Instructions</h1>
                </div>
                <p className="mb-6 text-base">{ heading_zero }</p>
                <p className="mb-6 text-base">{ heading_one }</p>
                <p className="mb-6 text-base">{ segment_1 }</p>
                <p className="mb-6 text-base">{ segment_2 }</p>
                <p className="mb-6 text-base">{ heading_two }</p>
                <p className="mb-6 text-base">{ heading_three }</p>
                <p className="mb-6 text-base">{ paragraph_one }</p>
                <p className="mb-6 text-base">{ heading_four }</p>
                <div className='text-center mb-5'>
                    <h1 className="text-2xl font-bold mb-4">Segment One Instructions</h1>
                </div>
                <p className="mb-6 text-base">{ segment_1_heading_one }</p>
                <p className="mb-6 text-base">{ segment_1_heading_two }</p>
                <p className="mb-6 text-base">{ segment_1_heading_three }</p>
                <p className="mb-6 text-base">{ segment_1_heading_four }</p>
                <p className="mb-6 text-base">{ segment_1_heading_five }</p>
                <p className="mb-6 text-base">{ segment_1_heading_six }</p>
                <div className='text-center mb-5'>
                    <h1 className="text-2xl font-bold mb-4">Segment Two Instructions</h1>
                </div>
                <p className="mb-6 text-base">{ segment_2_heading_one }</p>
                <p className="mb-6 text-base">{ segment_2_heading_two }</p>
                <p className="mb-6 text-base">{ segment_2_heading_three }</p>
                <p className="mb-6 text-base">{ segment_2_heading_four }</p>
                <p className="mb-6 text-base">{ segment_2_heading_five }</p>
                <div className='text-center mb-5'>
                    <h1 className="text-2xl font-bold mb-4">Marking Scheme</h1>
                </div>
                <table className="table-auto mx-auto">
                    <thead>
                        <tr>
                            {
                                marking_scheme_section_a_heading &&
                                <th className="border px-4 py-2">Section</th>
                            }
                            <th className="border px-4 py-2">Correct Attempt</th>
                            <th className="border px-4 py-2">Incorrect</th>
                            <th className="border px-4 py-2">Unattempted</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            className.marking_scheme?.section_a &&
                            <tr>
                                {
                                    marking_scheme_section_a_heading &&
                                    <td className="border px-4 py-2">{ marking_scheme_section_a_heading }</td>
                                }
                                <td className="border px-4 py-2">{ marking_scheme_section_a_correct_attempt }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_a_incorrect_attempt }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_a_unattempted }</td>
                            </tr>
                        }
                        {
                            className.marking_scheme?.section_b &&
                            <tr>
                                <td className="border px-4 py-2">{ marking_scheme_section_b_heading }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_b_correct_attempt }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_b_incorrect_attempt }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_b_unattempted }</td>
                            </tr>
                        }
                        {
                            className.marking_scheme?.section_c &&
                            <tr>
                                <td className="border px-4 py-2">{ marking_scheme_section_c_heading }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_c_correct_attempt }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_c_incorrect_attempt }</td>
                                <td className="border px-4 py-2">{ marking_scheme_section_c_unattempted }</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </Modal>
        </>
    );
}

export default SegmentInstructions;