import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import { useParams, useNavigate } from 'react-router-dom';
import {
    EditOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import useVerifyCouponCode from '../../api/VerifyCoupon';
import { setIsFormSubmitted, setIsLoadingScreen } from '../../redux/student/studentSlice';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';
import useFetchStudentDataEmail from '../../api/FetchStudentDetailsEmail';

const SignInCouponCode = () => {

    const { client_name, grade } = useParams();
    const [couponCode, setCouponCode] = useState( '' );
    const [email, setEmail] = useState( '' );
    const [isMailEditable, setIsMailEditable] = useState( false );
    const [isCouponCodeEditable, setIsCouponCodeEditable] = useState( false );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isWide = useMedia( '(min-width: 800px)' );

    const { response, isLoading, error, verifyCouponCode } = useVerifyCouponCode();
    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();

    const student_grade_local_storage = localStorage.getItem( 'student_grade' );
    const student_email = localStorage.getItem( 'student_email' );

    useEffect( () => {
        setEmail( student_email );
    }, [] )

    const handleEditCouponCode = () => {
        setIsCouponCodeEditable( true );
    }

    const handleNoEditCouponCode = () => {
        setIsCouponCodeEditable( false );
    }

    const handleEditEmail = () => {
        setIsMailEditable( true );
    }

    const handleNoEditEmail = () => {
        setIsMailEditable( false );
    }

    const handleVerify = () => {
        verifyCouponCode( couponCode, email );
        //FetchStudentDataEmail( email, client_name )
    }

    useEffect( () => {
        if ( response ) {
            if ( response && response.data && response.data === true ) {
                FetchStudentDataEmail( email, client_name )
                notification.success( {
                    message: 'Yayy! Coupon Verified',
                    description: ''
                } );
            } else {
                notification.info( {
                    message: 'This coupon code is not valid',
                    description: 'Please check your coupon code and tryb to verify again'
                } );
            }
        } else {

        }
    }, [response] )

    useEffect( () => {
        if ( studentData ) {
            const rollNumber = studentData?.data?.student_code;
            localStorage.setItem( 'student_roll_number', rollNumber )
            const grade = studentData?.data?.grade;
            const userAssessmentResponseID = studentData?.data?.assessment_id;
            if ( userAssessmentResponseID !== null ) {
                localStorage.setItem( 'userAssessmentResponseID', userAssessmentResponseID )
            }
            const assessmentStatus = studentData?.data?.assessment_status;
            const updatedAssessmentStatus = assessmentStatus === null ? 1 : assessmentStatus;
            localStorage.setItem( 'is_completed', updatedAssessmentStatus )
            if ( updatedAssessmentStatus !== 3 ) {
                dispatch( setIsFormSubmitted( false ) );
                navigate( `/${ client_name }/test/${ grade }/instructions` );
            } else {
                dispatch( setIsFormSubmitted( true ) );
                dispatch( setIsLoadingScreen( true ) );
                notification.success( {
                    message: 'You\'ve already given the test.',
                } );
                navigate( `/${ client_name }/test/${ grade }/thank_you` );
            }
        }
    }, [studentData] )

    return (
        <div>
            <div className="flex flex-col md:flex-row min-h-screen">
                <div className="bg-gradient-to-r from-yellow-300 to-orange-400 flex flex-col justify-center items-center md:w-2/5">
                    {
                        isWide ?
                            <>
                                <img className="mb-5" src="https://i.ibb.co/Vv2zyhR/jclogo.png" alt="logo" width="200" height="200" />
                            </>
                            :
                            <></>
                    }
                    <img className={ `${ isWide ? "" : "" }` } src={ `${ isWide ? 'https://i.ibb.co/B3nMWzk/Frame-1000006445.png' : 'https://i.ibb.co/ZMk1bBh/icat-logo.png' }` } alt="auth-graphic" border="0" />
                </div>
                <div className="flex justify-center items-center w-full md:w-3/5 mt-3 mb-3">
                    <>
                        <div className="w-[700px] flex flex-col">
                            <p className='text-2xl text-bold text-black '>Your Email</p>
                            <div>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    value={ email }
                                    disabled={ !isMailEditable }
                                    className={ `w-1/2 rounded ${ !isMailEditable ? "bg-gray-200" : "bg-white" } p-3 border-2 focus:border-blue-500` }
                                    style={ { resize: "none" } }
                                    onChange={ ( e ) => setEmail( e.target.value ) }
                                />
                                { !isMailEditable ?
                                    <EditOutlined
                                        onClick={ handleEditEmail }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                    />
                                    :
                                    <CheckCircleOutlined
                                        onClick={ handleNoEditEmail }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                    />
                                }
                            </div>
                            <div className='mt-10'>
                                <p className='text-2xl text-bold text-black '>Enter the Coupon Code Provided</p>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    value={ couponCode }
                                    onChange={ ( e ) => setCouponCode( e.target.value ) }
                                    disabled={ !isCouponCodeEditable }
                                    className={ `w-1/2 rounded ${ !isCouponCodeEditable ? "bg-gray-200" : "bg-white" } p-3 border-2 focus:border-blue-500` }
                                    style={ { resize: "none" } }
                                />
                                { !isCouponCodeEditable ?
                                    <EditOutlined
                                        onClick={ handleEditCouponCode }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                    />
                                    :
                                    <CheckCircleOutlined
                                        onClick={ handleNoEditCouponCode }
                                        style={ { fontSize: '25px' } }
                                        className="ml-4"
                                    />
                                }
                            </div>
                            <button
                                onClick={ handleVerify }
                                className="w-1/4 px-4 py-2 mt-10 rounded-md text-white bg-blue-500"
                            >
                                Verify
                            </button>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default SignInCouponCode;




