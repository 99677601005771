import { createSlice } from '@reduxjs/toolkit';

// Student Slice
export const studentSlice = createSlice( {
  name: 'student',
  initialState: {
    isStudent: false,
  },
  reducers: {
    setIsStudent: ( state, action ) => {
      state.isStudent = action.payload;
    },
  },
} );

export const { setIsStudent } = studentSlice.actions;

// Parent Slice
export const parentSlice = createSlice( {
  name: 'parent',
  initialState: {
    isParent: false,
  },
  reducers: {
    setIsParent: ( state, action ) => {
      state.isParent = action.payload;
    },
  },
} );

export const { setIsParent } = parentSlice.actions;

// client Slice
export const clientSlice = createSlice( {
  name: 'client',
  initialState: {
    isClient: false,
  },
  reducers: {
    setIsClient: ( state, action ) => {
      state.isClient = action.payload;
    },
  },
} );

export const { setIsClient } = clientSlice.actions;

//school regiustration slice
export const schoolRegistration = createSlice( {
  name: 'schoolRegistration',
  initialState: {
    isSchoolRegistration: false,
  },
  reducers: {
    setIsSchoolRegistration: ( state, action ) => {
      state.isSchoolRegistration = action.payload;
    },
  },
} );

export const { setIsSchoolRegistration } = schoolRegistration.actions;

//student regiustration slice
export const studentRegistration = createSlice( {
  name: 'studentRegistration',
  initialState: {
    isStudentRegistration: false,
  },
  reducers: {
    setIsStudentRegistration: ( state, action ) => {
      state.isStudentRegistration = action.payload;
    },
  },
} );

export const { setIsStudentRegistration } = studentRegistration.actions;

// Create a function to get the initial state based on localStorage
const getInitialState = () => {
  const storedQuestionNumber = localStorage.getItem( 'questionNumber' );
  return {
    questionNumber: storedQuestionNumber ? parseInt( storedQuestionNumber, 10 ) : 0,
  };
};

const currentQuestionSlice = createSlice( {
  name: 'currentQuestion',
  initialState: {
    questionNumber: 0
  },
  reducers: {
    setQuestionNumber: ( state, action ) => {
      state.questionNumber = action.payload;
      localStorage.setItem( 'questionNumber', action.payload );
    },
    resetQuestionNumber: ( state ) => {
      state.questionNumber = 0;
      localStorage.removeItem( 'questionNumber' );
    },
  },
} );

export const { setQuestionNumber, resetQuestionNumber } = currentQuestionSlice.actions;

//school regiustration slice
const isConsentFormLocalStorage = localStorage.getItem( 'isConsentForm' );

export const consentForm = createSlice( {
  name: 'consentForm',
  initialState: {
    isConsentForm: isConsentFormLocalStorage !== null ? JSON.parse( isConsentFormLocalStorage ) : false,
  },
  reducers: {
    setIsConsentForm: ( state, action ) => {
      state.isConsentForm = action.payload;
    },
  },
} );

export const { setIsConsentForm } = consentForm.actions;

//school regiustration slice
const isFormSubmittedLocalStorage = localStorage.getItem( 'isFormSubmitted' );

export const formSubmitted = createSlice( {
  name: 'formSubmitted',
  initialState: {
    isFormSubmitted: isFormSubmittedLocalStorage !== null ? JSON.parse( isFormSubmittedLocalStorage ) : false,
  },
  reducers: {
    setIsFormSubmitted: ( state, action ) => {
      state.isFormSubmitted = action.payload;
    },
  },
} );


export const { setIsFormSubmitted } = formSubmitted.actions;

const initialState = {
  textAnswers: JSON.parse( localStorage.getItem( 'textAnswers' ) ) || [],
};

//answers slice
export const answersSlice = createSlice( {
  name: 'answersSlice',
  initialState,
  reducers: {
    updateAnswerIndex: ( state, action ) => {
      const questionIndex = action.payload;
      state.textAnswers[questionIndex] = true;
      localStorage.setItem( 'textAnswers', JSON.stringify( state.textAnswers ) );
    },
    resetAnswerStatus: ( state, action ) => {
      const questionIndex = action.payload;
      state.textAnswers[questionIndex] = false;
      localStorage.setItem( 'textAnswers', JSON.stringify( state.textAnswers ) );
    },
    resetState: ( state ) => {
      state.textAnswers = [];
      localStorage.removeItem( 'textAnswers' );
    },
  },
} );

export const { updateAnswerIndex, resetAnswerStatus, resetState } = answersSlice.actions;

//school regiustration slice

const isLoadingScreenLocalStorage = localStorage.getItem( 'isLoadingScreen' );

export const loadingScreen = createSlice( {
  name: 'loadingScreen',
  initialState: {
    isLoadingScreen: isLoadingScreenLocalStorage !== null ? JSON.parse( isLoadingScreenLocalStorage ) : false,
  },
  reducers: {
    setIsLoadingScreen: ( state, action ) => {
      state.isLoadingScreen = action.payload;
    },
  },
} );

export const { setIsLoadingScreen } = loadingScreen.actions;

//Required Questions Attempted slice
export const requiredQuestionsAttempted = createSlice( {
  name: 'requiredQuestionsAttempted',
  initialState: {
    isRequiredQuestionsAttempted: false,
  },
  reducers: {
    setIsRequiredQuestionsAttempted: ( state, action ) => {
      state.isRequiredQuestionsAttempted = action.payload;
    },
  },
} );

export const { setIsRequiredQuestionsAttempted } = requiredQuestionsAttempted.actions;

// Export all reducers
export const studentReducer = studentSlice.reducer;
export const parentReducer = parentSlice.reducer;
export const clientReducer = clientSlice.reducer;
export const schoolRegistrationReducer = schoolRegistration.reducer;
export const studentRegistrationReducer = studentRegistration.reducer;
export const currentQuestionReducer = currentQuestionSlice.reducer;
export const consentFormReducer = consentForm.reducer;
export const updateAnswerReducer = answersSlice.reducer;
export const formSubmittedReducer = formSubmitted.reducer;
export const loadingScreenReducer = loadingScreen.reducer;
export const requiredQuestionsAttemptedReducer = requiredQuestionsAttempted.reducer;


